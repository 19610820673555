import { observable, action, reaction } from 'mobx'
import { AssetsToken } from '../tokenStore/type/AssetsToken'
import {
  CurrencyItem,
  HistoryItem,
  QuoteRes,
  QuoteSide,
  SupportCryptoItem,
  SupportPayWay
} from '@/api/ramp/type'

const STORAGE_KEY = 'ramp_store_history_list'

const loadHistoryList = (): HistoryItem[] => {
  const arr = JSON.parse(localStorage.getItem(STORAGE_KEY) ?? '[]')
  return arr || []
}

const saveHistoryList = (list: HistoryItem[]): void => {
  const str = JSON.stringify(list)
  localStorage.setItem(STORAGE_KEY, str)
}

const loadSelectedToken = (): AssetsToken => {
  const str = localStorage.getItem('ramp_store_selected_token')
  return str ? JSON.parse(str) : {}
}

const saveSelectedToken = (token: AssetsToken): void => {
  const str = JSON.stringify(token)
  localStorage.setItem('ramp_store_selected_token', str)
}

export interface IRampStore {
  selectedToken: AssetsToken
  selectedTab: QuoteSide
  isAlchemySupported: boolean
  supportCryptoList: SupportCryptoItem[]
  buySelectedCurrency: CurrencyItem
  sellSelectedCurrency: CurrencyItem
  buyCurrencyList: CurrencyItem[]
  sellCurrencyList: CurrencyItem[]
  selectPayWayCode: SupportPayWay
  buyAmount: string
  sellAmount: string
  alchemyUserAccountId: string
  buyQuote: QuoteRes
  sellQuote: QuoteRes
  historyList: HistoryItem[]
  setSelectedToken: (token: AssetsToken) => void
  clearSelectedToken: () => void
  setSelectedTab: (tab: QuoteSide) => void
  clearSelectedTab: () => void
  setSupportCryptoList: (list: SupportCryptoItem[]) => void
  clearSupportCryptoList: () => void
  setIsAlchemySupported: (isSupported: boolean) => void
  clearIsAlchemySupported: () => void
  setBuySelectedCurrency: (currency: CurrencyItem) => void
  clearBuySelectedCurrency: () => void
  setSellSelectedCurrency: (currency: CurrencyItem) => void
  clearSellSelectedCurrency: () => void
  setBuyCurrencyList: (currencies: CurrencyItem[]) => void
  clearBuyCurrencyList: () => void
  setSellCurrencyList: (currencies: CurrencyItem[]) => void
  clearSellCurrencyList: () => void
  setSelectPayWayCode: (payWayCode: SupportPayWay) => void
  setAlchemyUserAccountId: (accountId: string) => void
  setBuyAmount: (amount: string) => void
  setSellAmount: (amount: string) => void
  setBuyQuote: (quote: QuoteRes) => void
  setSellQuote: (quote: QuoteRes) => void
  setHistoryList: (list: HistoryItem[]) => void
  clearAmount: () => void
}

const rampStore: IRampStore = observable({
  selectedToken: loadSelectedToken(),
  selectedTab: QuoteSide.BUY,
  supportCryptoList: [] as SupportCryptoItem[],
  isAlchemySupported: false,
  buySelectedCurrency: {} as CurrencyItem,
  sellSelectedCurrency: {} as CurrencyItem,
  buyCurrencyList: [] as CurrencyItem[],
  sellCurrencyList: [] as CurrencyItem[],
  selectPayWayCode: '' as SupportPayWay,
  alchemyUserAccountId: '',
  buyAmount: '',
  sellAmount: '',
  buyQuote: {} as QuoteRes,
  sellQuote: {} as QuoteRes,
  historyList: loadHistoryList(),
  setHistoryList: action(function (list: HistoryItem[]) {
    rampStore.historyList = list
    saveHistoryList(list)
  }),

  setSelectedToken: action(function (token: AssetsToken) {
    rampStore.selectedToken = token
    saveSelectedToken(token)
  }),

  clearSelectedToken: action(function () {
    rampStore.selectedToken = {} as AssetsToken
  }),

  setSelectedTab: action(function (tab: QuoteSide) {
    rampStore.selectedTab = tab
  }),

  clearSelectedTab: action(function () {
    rampStore.selectedTab = QuoteSide.BUY
  }),

  setSupportCryptoList: action(function (list: SupportCryptoItem[]) {
    rampStore.supportCryptoList = list
  }),

  clearSupportCryptoList: action(function () {
    rampStore.supportCryptoList = [] as SupportCryptoItem[]
  }),

  setIsAlchemySupported: action(function (isSupported: boolean) {
    rampStore.isAlchemySupported = isSupported
  }),

  clearIsAlchemySupported: action(function () {
    rampStore.isAlchemySupported = false
  }),

  setBuySelectedCurrency: action(function (currency: CurrencyItem) {
    rampStore.buySelectedCurrency = currency
  }),

  clearBuySelectedCurrency: action(function () {
    rampStore.buySelectedCurrency = {} as CurrencyItem
  }),

  setSellSelectedCurrency: action(function (currency: CurrencyItem) {
    rampStore.sellSelectedCurrency = currency
  }),

  clearSellSelectedCurrency: action(function () {
    rampStore.sellSelectedCurrency = {} as CurrencyItem
  }),

  setBuyCurrencyList: action(function (currencies: CurrencyItem[]) {
    rampStore.buyCurrencyList = currencies
  }),

  clearBuyCurrencyList: action(function () {
    rampStore.buyCurrencyList = [] as CurrencyItem[]
  }),

  setSellCurrencyList: action(function (currencies: CurrencyItem[]) {
    rampStore.sellCurrencyList = currencies
  }),

  clearSellCurrencyList: action(function () {
    rampStore.sellCurrencyList = [] as CurrencyItem[]
  }),
  setSelectPayWayCode: action(function (payWayCode: SupportPayWay) {
    rampStore.selectPayWayCode = payWayCode
  }),
  setAlchemyUserAccountId: action(function (accountId: string) {
    rampStore.alchemyUserAccountId = accountId
  }),
  setBuyAmount: action(function (amount: string) {
    rampStore.buyAmount = amount
  }),
  setSellAmount: action(function (amount: string) {
    rampStore.sellAmount = amount
  }),
  setBuyQuote: action(function (quote: QuoteRes) {
    rampStore.buyQuote = quote
  }),
  setSellQuote: action(function (quote: QuoteRes) {
    rampStore.sellQuote = quote
  }),
  clearAmount: action(function () {
    rampStore.sellAmount = ''
    rampStore.buyAmount = ''
  }),

  get hasSelectedToken() {
    return rampStore.selectedToken !== null
  }
})

reaction(
  () => rampStore.historyList,
  (historyList) => saveHistoryList(historyList)
)

export default rampStore
