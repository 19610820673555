import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { berachainTestnetbArtio } from '@wagmi/core/chains'

const icon = chainsSvgs.berachainTestnetSvg
const networkType: IWeb3NetworkType = 'test'

const chainInfo: IWeb3ChainType = {
  chain: berachainTestnetbArtio,
  id: 80_084,
  type: 'EVM',
  name: berachainTestnetbArtio.name,
  icon: icon,
  networkType
}

export default chainInfo
