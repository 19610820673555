// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyALREIyL-3neeql1WtREnrikePcbI-TSn0',
  authDomain: 'tomo-9c721.firebaseapp.com',
  projectId: 'tomo-9c721',
  storageBucket: 'tomo-9c721.firebasestorage.app',
  messagingSenderId: '479465761311',
  appId: '1:479465761311:web:c1cfdaf558086c4df0432a',
  measurementId: 'G-GFS9KGE0F6'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

console.log('firebase init')
