import chains from 'proviers/web3Provider/chains'
import { IChainId } from 'proviers/web3Provider/type'
import { useChaiSymbolnConfig } from '../../../hooks/api/useChainConfig'
import { mockTonOkxChainID } from 'config/ton'
import { ISwapTokenType } from '@/pages/swap/hooks/type'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'

const useChains = () => {
  const { data } = useChaiSymbolnConfig()

  const getChainIdByName = (
    value: string | undefined
  ): IChainId | undefined => {
    if (data && value) {
      for (const [key, val] of Object.entries(data.chain_id_name)) {
        if (val === value) {
          return Number(
            key == mockTonOkxChainID.toString() ? chains.ton.id.toString() : key
          ) as IChainId
        }
      }
    }
    return undefined
  }

  const getChainNameById = (chainId: IChainId | undefined) => {
    const apiChainId = chainId === chains.ton.id ? mockTonOkxChainID : chainId
    if (data && typeof apiChainId === 'number') {
      return data.chain_id_name[apiChainId]
    }
    return undefined
  }

  const getITokenBySwapToken = (
    swapToken: ISwapTokenType | undefined
  ): AssetsToken | undefined => {
    if (!swapToken) return
    const assets: AssetsToken = {
      isNative: !swapToken?.address,
      isToken: !!swapToken?.address,
      chainId: getChainIdByName(swapToken?.chain) ?? -1,
      decimals: swapToken?.decimals ?? 18,
      symbol: swapToken?.symbol ?? '',
      name: swapToken?.name ?? '',
      address: swapToken?.address ?? '',
      balance: '0',
      price: Number(swapToken?.priceUsd),
      image: swapToken?.imageUrl ?? '',
      source: 'all',
      id: `${swapToken?.address ?? ''}-${
        getChainIdByName(swapToken?.chain) ?? -1
      }-${swapToken?.symbol ?? ''}`,
      formatted: '0',
      whiteToken: undefined,
      customToken: {
        ID: 0,
        chain_id: getChainIdByName(swapToken?.chain) ?? -1,
        created_at: 0,
        decimals: swapToken?.decimals ?? 18,
        image: swapToken?.imageUrl ?? '',
        market_cap: swapToken?.marketCap ?? 0,
        name: swapToken?.name ?? '',
        price: Number(swapToken?.priceUsd),
        price_change_h24: swapToken?.priceChangeH24 ?? 0,
        symbol: swapToken?.symbol ?? '',
        token: swapToken?.symbol ?? '',
        uid: 0
      }
    }
    return assets
  }

  return {
    getChainIdByName,
    getChainNameById,
    getITokenBySwapToken
  }
}

export default useChains
