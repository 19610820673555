import { AxiosInstance, AxiosResponse } from 'axios'
import { SuccessHttpCodeList } from './const'
import { sentryCaptureException } from './sentryCaptureException'
import {
  createSentrySpan,
  endSentrySpan,
  handleSentrySpanError
} from './handleSentrySpan'

export const sentryInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    try {
      createSentrySpan(config)
    } catch (error) {
      sentryCaptureException(<Error>error)
    }
    return config
  })
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      try {
        endSentrySpan(response)
        // Interface business error reporting to Sentry
        const { code, msg, message } = response?.data || {}
        if (code && !SuccessHttpCodeList.includes(Number(code))) {
          const url = response?.config?.url || ''
          const _msg = msg || message || 'unknown'
          const errMsg = `【api】response biz error, url: ${url}; code: ${code}; msg: ${_msg}`
          sentryCaptureException(errMsg, {
            extra: {
              config: response?.config,
              res: response?.data
            }
          })
        }
      } catch (error) {
        sentryCaptureException(<Error>error)
      }
      return response
    },
    (error: Record<string, any>) => {
      try {
        handleSentrySpanError(error)
        const { config, response } = error || {}
        const url = config?.url || ''
        const code = error?.code || error?.status || 'unknown'
        const msg = response?.data?.message || error?.message || 'unknown'
        const errMsg = `【api】response error, url: ${url}; code: ${code}; msg: ${msg}`
        sentryCaptureException(errMsg, {
          extra: {
            config: response?.config,
            res: response?.data
          }
        })
      } catch (error) {
        sentryCaptureException(<Error>error)
      }
      return Promise.reject(error)
    }
  )
}
