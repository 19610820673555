import classNames from 'classnames'
import { MainButtonProps } from './PropsType'
import { observer } from 'mobx-react-lite'
import commonStore from '@/stores/commonStore'
import { SafeArea } from 'antd-mobile'
import { useMemo } from 'react'
import userStore from '@/stores/userStore'
import { tgDeskPlatform } from '@/utils/telegram'

const MainButton = (props: MainButtonProps) => {
  const { children, className, ...restProps } = props

  const isStatic = useMemo(() => {
    if (
      commonStore.mayKeyboardOpen &&
      !tgDeskPlatform.includes(userStore.biometry.platform)
    ) {
      return true
    }
    return false
  }, [commonStore.mayKeyboardOpen, userStore.biometry.platform])

  return (
    <>
      <div
        className={classNames(
          'w-full',
          classNames({
            'opacity-0': commonStore.mayKeyboardOpen
          })
        )}
      >
        {children}
      </div>

      <div
        className={classNames(
          'hidden',
          {
            '!block fixed bottom-0 left-0 right-0 w-full px-5 py-2 bg-bg1 z-20':
              isStatic
          },
          className
        )}
        {...restProps}
      >
        {children}
        {isStatic && <SafeArea position="bottom" />}
      </div>
    </>
  )
}

export default observer(MainButton)
