import { Loading } from '@/components/Loading'
import { TPopup } from '@/components/tmd'
import classNames from 'classnames'
import { CSSProperties, useState } from 'react'

const usePopup = ({
  title,
  content,
  showCloseButton = true,
  closeIcon,
  onClose,
  contentClassName,
  afterClose,
  initVisible = false,
  onMaskClick,
  maskGesture = true,
  fullscreen = false,
  // customscreen = false,
  className,
  afterShow,
  bodyStyle,
  iconClassName
}: {
  title?: string
  content: React.ReactNode
  showCloseButton?: boolean
  closeIcon?: React.ReactNode
  onClose?: () => void
  contentClassName?: string
  afterClose?: () => void
  initVisible?: boolean
  maskGesture?: boolean
  onMaskClick?: () => void
  className?: string
  fullscreen?: boolean
  // customscreen?: boolean
  afterShow?: () => void
  bodyStyle?: CSSProperties
  iconClassName?: string
}) => {
  const [open, setOpen] = useState(initVisible)
  const [loading, setLoading] = useState(false)

  const bodyClassName = {
    'h-[calc(100vh-41px)]': fullscreen
  }

  return {
    open,
    setOpen,
    component: (
      <TPopup
        title={title}
        visible={open}
        showCloseButton={showCloseButton}
        onMaskClick={() => {
          if (maskGesture) {
            setOpen(false)
            onMaskClick?.()
          }
        }}
        onClose={() => {
          onClose && onClose()
          setOpen(false)
        }}
        closeIcon={closeIcon}
        afterClose={afterClose}
        className={className}
        bodyStyle={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          ...bodyStyle
        }}
        afterShow={afterShow}
        bodyClassName={classNames('flex flex-col', bodyClassName)}
        iconClassName={iconClassName}
      >
        <div className={classNames('px-5 pb-2 flex-1', contentClassName)}>
          {loading ? <Loading></Loading> : open && content}
        </div>
      </TPopup>
    )
  }
}
export default usePopup
