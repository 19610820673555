import { useMemo } from 'react'
import { TButton, useTranslation } from '@/components/tmd'
import userStore from '@/stores/userStore'
import useUserStore from '@/stores/userStore/hooks/useUserStore'

const LaunchControlBtn = () => {
  const { t } = useTranslation()
  const { userState, autoLogin } = useUserStore()
  const showFlag = useMemo(() => {
    return userState.frozen || !userState.userId || !autoLogin
  }, [autoLogin, userState.frozen, userState.userId])

  if (showFlag) return <></>
  return (
    <div className="bottom-0 w-full pt-[45px]">
      <TButton size="large" block onClick={userStore.loginAction}>
        <span className="text-base font-medium text-white">
          {t('tg_wallet_login.launch_btn')}
        </span>
      </TButton>
    </div>
  )
}

export default LaunchControlBtn
