/* eslint-disable @typescript-eslint/ban-ts-comment */
import { alchemyPay, mastercardSvg } from '@/assets'
import Countdown from './components/Countdown'
import Order from './components/Order'
import rampStore from '@/stores/rampStore'
import {
  HistoryItem,
  IQuoteParams,
  QuoteRes,
  QuoteResponse,
  QuoteSide
} from '@/api/ramp/type'
import { useMutation } from '@tanstack/react-query'
import { createOrder, getQuote } from '@/api/ramp'
import { marketChain } from '@/proviers/web3Provider/chains'
import useLoginInfo from '@/hooks/useLoginInfo'
import { useState } from 'react'
import {
  TConfirmButton,
  TContainer,
  TIcon,
  TLoadingButton
} from '@/components/tmd'
// import { toast } from 'react-toastify'
import toast from '@/components/tmd/toast/Toast'
import { useNavigate, useParams } from 'react-router-dom'
import { BackButton } from '@vkruglikov/react-telegram-web-app'
import { getCookieQuery } from './utils'

export const ConfirmPayment: React.FC = () => {
  const { lastFourDigits, cardBrand } = useParams()
  const navigate = useNavigate()
  const [merchantNo, setMerchantNo] = useState('')
  const [loading, setLoading] = useState(false)
  const type = rampStore.selectedTab
  const quote =
    type === QuoteSide.BUY ? rampStore.buyQuote : rampStore.sellQuote
  const [buyQoute, setBuyQoute] = useState<QuoteRes>(quote)
  const selectedToken = rampStore.selectedToken
  const amount = rampStore.buyAmount
  const getAddressByToken = useLoginInfo().getAddressByToken
  const setQuote = rampStore.setBuyQuote
  const clearAmount = rampStore.clearAmount
  const setHistoryList = rampStore.setHistoryList

  const selectCurrency =
    type === QuoteSide.BUY
      ? rampStore.buySelectedCurrency
      : rampStore.sellSelectedCurrency

  const orderCreateMutation = useMutation({
    mutationFn: createOrder,
    onSuccess: (data) => {
      setLoading(false)
      if (!data?.data) {
        toast.error(data?.returnMsg || 'Connect alchemy error, try again later')
        return
      }
      const item: HistoryItem = {
        side: type,
        cryptoCurrency: rampStore.selectedToken.symbol,
        address: getAddressByToken(rampStore.selectedToken),
        tokenAddress: rampStore.selectedToken.address,
        network: marketChain[rampStore.selectedToken?.chainId].chain,
        amount: rampStore.buyAmount,
        fiatCurrency: selectCurrency.currency,
        orderType: '4',
        merchantOrderNo: merchantNo,
        payWayCode: rampStore.selectPayWayCode,
        userAccountId: rampStore.alchemyUserAccountId.toString(),
        redirectUrl: import.meta.env.VITE_BOT_LINK + `?startapp=${merchantNo}`,
        callbackUrl: '',
        quote: quote,
        orderNo: data.data.orderNo,
        webUrl: data.data.webUrl,
        txHash: ''
      }
      setHistoryList([item, ...rampStore.historyList])

      clearAmount()
      if (data.data.webUrl) {
        window.location.href = data.data.webUrl
      }
    },
    onError: (e) => {
      toast.error(e.message || 'Connect alchemy error, try again later')
    }
  })

  const onConfirm = async () => {
    setLoading(true)
    const no = `${Date.now()}${Math.floor(Math.random() * 10000)}`
    setMerchantNo(no)
    let cookieQuery = {
      cookie: '',
      channalCookie: '',
      clientIp: ''
    }
    try {
      cookieQuery = await getCookieQuery()
    } catch (e) {
      setLoading(false)
      toast.error('Get alchemy cookies error, try again')
      return
    }
    if (!cookieQuery.channalCookie || !cookieQuery.cookie) {
      toast('Please enable cookies')
      return
    }
    const jumpURL = import.meta.env.VITE_BOT_LINK + `?startapp=RAMP_${no}`
    orderCreateMutation.mutate({
      side: type,
      merchantOrderNo: no,
      cryptoCurrency: rampStore.selectedToken.symbol,
      address: getAddressByToken(rampStore.selectedToken),
      network: marketChain[rampStore.selectedToken?.chainId].chain,
      amount: rampStore.buyAmount,
      fiatCurrency: selectCurrency.currency,
      orderType: '4',
      payWayCode: rampStore.selectPayWayCode,
      userAccountId: rampStore.alchemyUserAccountId.toString(),
      // redirectUrl: `https://tomo.inc/union?rampNo=${no}`,
      redirectUrl: `https://tomo.inc/union?url=${encodeURIComponent(jumpURL)}`,
      callbackUrl: '',
      extendParams: {
        cookie: cookieQuery.cookie,
        channelCookie: cookieQuery.channalCookie
      },
      clientIp: cookieQuery.clientIp
    })
  }

  const getBuyQuote = useMutation<QuoteResponse, unknown, IQuoteParams>({
    mutationFn: (params: IQuoteParams) =>
      getQuote(params).then((res) => res as QuoteResponse),
    onSuccess: (data: QuoteResponse) => {
      if (!data.data && data.returnCode === '99999') {
        toast.error(data.returnMsg)
        return
      }
      if (data.data) {
        setQuote(data.data)
        setBuyQoute(data.data)
      }
    },
    onError: () => {
      toast.error('Get estimate error, try again later.')
    }
  })

  const loadQuote = async (amount: string) => {
    if (!amount) return
    getBuyQuote.mutate({
      side: QuoteSide.BUY,
      amount: amount,
      network: marketChain[rampStore.selectedToken?.chainId].chain,
      crypto: selectedToken?.symbol ?? '',
      fiat: rampStore.buySelectedCurrency?.currency ?? ''
    })
  }
  return (
    <>
      {!loading && <BackButton onClick={() => navigate(-1)} />}
      <TContainer className="h-full">
        <div className="flex h-full flex-col justify-between">
          <div>
            <div className="flex h-[52px] items-center">
              <span className="text-xl font-semibold text-t1">
                Choose payment method
              </span>
            </div>
            <div className="flex flex-col pt-1">
              <div className="text-base font-medium text-t1">Order summary</div>
              <div className="mt-3 rounded-lg bg-bg3 px-4 py-[15px]">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium text-t3 ">
                    Your chosen payment method is
                  </span>
                  <span className="text-base font-medium text-blue">
                    {'Card'}
                  </span>
                </div>
                <div className=" my-3 border-b border-l1"></div>
                <div className="flex items-center gap-[10px]">
                  <TIcon
                    name="tg_wallet_Card"
                    fontSize="24"
                    className="text-t2"
                  />
                  <span className="text-center text-sm text-t3">
                    **** {lastFourDigits}
                  </span>
                </div>
              </div>
              {buyQoute && (
                <Countdown
                  start={!!buyQoute}
                  reloadFun={loadQuote}
                  amount={amount}
                />
              )}
              {buyQoute && (
                <div className="mt-[-14px]">
                  <Order type={type} data={buyQoute} amount={amount} />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-[6px]">
            {loading ? (
              <TLoadingButton disabled={true} />
            ) : (
              <TConfirmButton
                disabled={loading}
                onConfirm={onConfirm}
                text="Slide to proceed"
              />
            )}
            <div className="my-[6px] flex items-center justify-center">
              <span className="text-sm text-t3">Powered by</span>
              <img className="h-5" src={alchemyPay} alt="" />
            </div>
          </div>
        </div>
      </TContainer>
    </>
  )
}
