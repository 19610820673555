import AdaptiveNumber, { NumberType } from '@/components/AdaptiveNumber'
import { Loading } from '@/components/tmd'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import { shortenAddress } from '@/utils/helper'
import BigNumber from 'bignumber.js'

const Item = ({ children }: { children: JSX.Element }) => {
  return <div className="flex h-[34px] justify-between ">{children}</div>
}

const StarSwapInfo = ({
  rate,
  wallet,
  token,
  loading
}: {
  rate: BigNumber
  wallet: string
  token: AssetsToken
  loading: boolean
}) => {
  return (
    <div className="mt-[26px] flex flex-col">
      <Item>
        <>
          <span className="text-xs text-t2">Exchange rates</span>
          <span className="text-xs text-t1">
            {!loading ? (
              <>
                <span>100 star = </span>
                <AdaptiveNumber
                  value={rate.times(100).toFixed()}
                  type={NumberType.BALANCE}
                />
                <span> {token.symbol}</span>
              </>
            ) : (
              <Loading />
            )}
          </span>
        </>
      </Item>
      <Item>
        <>
          <span className="text-xs text-t2">Current wallet</span>
          <span className="text-xs text-t1">{shortenAddress(wallet)}</span>
        </>
      </Item>
    </div>
  )
}

export default StarSwapInfo
