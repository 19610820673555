import { TIcon } from '@/components/tmd'
import usePopup from '@/hooks/popup/usePopup'

const useAlPolicy = ({
  onSkip,
  onConfirm,
  afterClose
}: {
  onSkip: () => void
  onConfirm?: () => void
  afterClose?: () => void
}) => {
  return usePopup({
    afterClose,
    showCloseButton: true,
    maskGesture: true,
    closeIcon: <TIcon name="tg_wallet_disable text-t1" fontSize="20" />,
    // bodyStyle: {
    //   height: 'calc(100vh - 15px)'
    // },
    fullscreen: true,
    contentClassName: 'flex flex-col justify-between',
    content: (
      <div className="flex size-full">
        <iframe
          src="https://alchemypay.org/privacy-policy"
          className="size-full"
        />
      </div>
    )
  })
}

export default useAlPolicy
