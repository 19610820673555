// import { Config, getHttpEndpoint, Network } from '@orbs-network/ton-access'
// import TronWeb from 'tronweb'
import { erc20Abi, formatUnits } from 'viem'

// export const mockTronChainId = 728126428
export const mockTronChainId = 19484
export const tronDecimals = 6
export const mockTronOKXChainId = 195 // this chainId for okx api
export const OkxChainIdToMockIdMap = {
  [mockTronOKXChainId]: mockTronChainId
}
export const MockIdToOkxChainIdMap = {
  [mockTronChainId]: mockTronOKXChainId
}
export const tronScanUrl = 'https://tronscan.org/#/transaction/'
// 19484
// https://api.trongrid.io
export async function getTronWebProvider() {
  const { default: TronWeb } = await import('tronweb')
  return new TronWeb({
    fullHost: 'https://api.trongrid.io'
    // 'https://rpc.ankr.com/premium-http/tron/3b62cdcc3f724166df4c53366e9acad6ccfab7299c589a1e17f000a0c5aac75'
    // headers: { 'TRON-PRO-API-KEY': import.meta.env.VITE_TRON_API_KEY }
  })
}

export async function getBalance({
  account,
  token
}: {
  account: string
  token?: string
}): Promise<{
  value: bigint
  formatted: string
  decimals: number
  symbol: string
  token: string | undefined
  name?: string | undefined
}> {
  const tronWeb = await getTronWebProvider()
  tronWeb.setAddress(account)
  let balance: bigint
  let decimals: number
  let symbol: string
  let name: string
  if (token) {
    const contract = await tronWeb.contract(erc20Abi, token)
    const balanceResponse = await contract.balanceOf(account).call()
    balance = BigInt(balanceResponse)
    const decimalsResponse = await contract.decimals().call()
    decimals = parseInt(decimalsResponse)
    symbol = await contract.symbol().call()
    name = await contract.name().call()
  } else {
    const accountInfo = await tronWeb.trx.getAccount(account)
    balance = BigInt(accountInfo?.balance || '0')
    symbol = 'TRX'
    decimals = tronDecimals
    name = 'TRX'
  }

  const formatted = formatUnits(balance, decimals)
  return {
    value: balance,
    formatted: formatted,
    decimals: decimals,
    symbol: symbol,
    token: token,
    name
  }
}

export async function sendTransaction({
  amount,
  from,
  to,
  contract
}: {
  amount: bigint
  from: string
  to: string
  contract?: string
}) {
  const tronWeb = await getTronWebProvider()
  tronWeb.setAddress(from)
  let tx: any
  if (contract) {
    const functionSelector = 'transfer(address,uint256)'
    const res = await tronWeb.transactionBuilder.triggerSmartContract(
      contract,
      functionSelector,
      {},
      [
        { type: 'address', value: to },
        { type: 'uint256', value: amount.toString() }
      ]
    )
    if (res.result) {
      tx = res.transaction
    }
  } else {
    tx = await tronWeb.transactionBuilder.sendTrx(to, amount.toString(), from)
  }

  return tx
  // const signedTx = await tronWeb.trx.sign(tx.transaction)
  // const result = await tronWeb.trx.sendRawTransaction(signedTx)
}

export async function sendRawTransaction({
  rawTransaction
}: {
  rawTransaction: any
}) {
  const tronWeb = await getTronWebProvider()
  // tronWeb.setAddress(from)
  const result = await tronWeb.trx.sendRawTransaction(rawTransaction)
  return result
}

export async function getTransaction(txId: string): Promise<any> {
  const tronWeb = await getTronWebProvider()

  try {
    const transaction = await tronWeb.trx.getTransaction(txId)
    return transaction
  } catch (error) {
    console.error(`Failed to fetch transaction by ID ${txId}:`, error)
    throw error
  }
}

export async function getTxFee({
  token,
  from,
  amount,
  to
}: {
  token: string | undefined
  from: string
  amount: bigint
  to: string
}): Promise<
  | {
      result: {
        result: boolean
      }
      energy_used: number
      constant_result: string[]
      logs: {
        address: string
        data: string
        topics: string[]
      }[]
      transaction: {
        ret: any[]
        visible: boolean
        txID: string
        raw_data: any
        raw_data_hex: string
      }
    }
  | undefined
> {
  const tronWeb = await getTronWebProvider()

  try {
    if (token) {
      const energyEstimate =
        await tronWeb.transactionBuilder.triggerConstantContract(
          token,
          'transfer(address,uint256)',
          {
            feeLimit: 150_000_000,
            callValue: 0
          },
          [
            {
              type: 'address',
              value: to
            },
            {
              type: 'uint256',
              value: amount.toString()
            }
          ],
          from
        )

      return energyEstimate
    } else {
      const bandwidth = await tronWeb.trx.getBandwidth(from)
      const a = await tronWeb.trx.getBandwidthPrices()
      const f = await tronWeb.trx.getAccountResources(from)
      console.log({
        bandwidth,
        a,
        f
      })
    }
  } catch (error) {
    console.error('Error estimating energy:', error)
    throw error
  }
}
