import chains from '@/proviers/web3Provider/chains'

export enum NativeTokenSymbol {
  ETH = 'ETH',
  TON = 'TON'
}

export const nativeTokenFilter = ({
  isNative,
  symbol,
  chainId
}: {
  isNative: boolean
  symbol: string
  chainId: number
}) => {
  if (isNative) {
    if (symbol.includes(NativeTokenSymbol.ETH)) {
      return NativeTokenSymbol.ETH
    }
    if (symbol.includes(NativeTokenSymbol.TON) && chainId !== chains.ton.id) {
      return NativeTokenSymbol.TON
    }
  }
}
