import { ISwapTokenTypeV2 } from '@/pages/swap/hooks/useSwapTokensV2'
import TokenImg from '@/pages/wallet/components/TokenImg'
import { Loading } from '../../SwapLoading'
import { TIcon } from '@/components/tmd'

const SwapToken = ({
  token,
  chainId,
  onClick,
  disabled = false
}: {
  token: ISwapTokenTypeV2 | undefined
  chainId: number
  onClick?: () => void
  disabled?: boolean
}) => {
  if (!token) {
    return <Loading />
  }

  return (
    <div
      className="flex shrink-0 items-center"
      onClick={(e) => {
        e.stopPropagation()
        if (onClick) {
          onClick()
        }
      }}
    >
      <TokenImg
        symbol={token?.symbol}
        image={token.imageUrl}
        chainId={chainId ?? -1}
        isNative={token?.isNative}
        // symbolSize={34}
        chainSize={16}
      />
      <span className="ml-3 mr-1 text-xl font-bold text-t1">
        {token?.symbol.toLocaleUpperCase()}
      </span>
      {!disabled && (
        <TIcon
          className="text-t1"
          fontSize="24"
          name={'tg_wallet_pull-down1'}
        />
      )}
    </div>
  )
}

export default SwapToken
