import { useMutation, useQuery, keepPreviousData } from '@tanstack/react-query'
import { addChainIdToApiReq } from '@/stores/tokenStore/util'
import {
  favouriteTokens,
  getAllTokens,
  getFavouriteTokens,
  getMemesOverview,
  getSelectedTokens,
  getTokenByName,
  getTokenPrices,
  getTokenSearch,
  getTrendingTokens,
  IntervalType
} from 'api'

// --------------------------------------------------------
export const useTrendingTokens = (params?: { chain?: string }) => {
  return useQuery({
    // staleTime: 30_000,
    refetchInterval: 30_000,
    queryKey: ['market-trending-tokens', params],
    queryFn: async () => {
      const data = await getTrendingTokens(params)
      return addChainIdToApiReq(data)
    },
    placeholderData: keepPreviousData
  })
}

// --------------------------------------------------------
export const useSelectedTokens = () => {
  return useQuery({
    staleTime: 30_000,
    queryKey: ['market-selected-tokens'],
    queryFn: async () => {
      const data = await getSelectedTokens()
      return addChainIdToApiReq(data)
    }
  })
}

// --------------------------------------------------------
export const useTokens = () => {
  return useQuery({
    staleTime: 60_000,
    queryKey: ['market-all-tokens'],
    queryFn: getAllTokens
  })
}

// --------------------------------------------------------
export const useTokenByName = (tokenName: string) => {
  return useQuery({
    enabled: !!tokenName?.trim(),
    staleTime: 30_000,
    queryKey: ['use-token-by-name', tokenName],
    queryFn: () => getTokenByName(tokenName)
  })
}

// --------------------------------------------------------
export const useTokenSearch = (content: string, chain: string) => {
  return useQuery({
    staleTime: 30_000,
    queryKey: ['use-token-search', content, chain],
    // queryFn: () => getTokenSearch(content, chain)
    queryFn: async () => {
      const res = await getTokenSearch(content, chain)
      return addChainIdToApiReq(res)
    }
  })
}

// --------------------------------------------------------
export const useTokenPrices = (
  tokenName: string,
  interval: IntervalType,
  isTestnet: boolean
) => {
  return useQuery({
    staleTime: 10_000,
    queryKey: ['use-token-prices', tokenName, interval],
    queryFn: () => {
      if (isTestnet) {
        return null
      }
      return getTokenPrices(tokenName, interval)
    }
  })
}

// --------------------------------------------------------
export const useMemesOverview = (chain: string, address: string) => {
  return useQuery({
    enabled: !!chain,
    staleTime: 30_000,
    queryKey: ['use-memes-overview', chain, address],
    queryFn: () => getMemesOverview(chain, address)
  })
}

// --------------------------------------------------------

export const useGetFavouriteTokens = () => {
  return useQuery({
    // staleTime: 3000,
    refetchInterval: 20_000,
    queryKey: ['use-get-favourite-tokens'],
    queryFn: async () => {
      const data = await getFavouriteTokens()
      return addChainIdToApiReq(data)
    }
  })
}

export const useFavouriteTokens = () => {
  return useMutation({
    mutationKey: ['use-favourite-tokens'],
    mutationFn: favouriteTokens
  })
}
