import classNames from 'classnames'
import { NoResultProps } from './PropsType'
import { mergeProps } from '../utils/get-default-props'
import { isValidElement, useMemo } from 'react'
import { getSizeStyle } from '../utils/format/unit'
import useTheme from '@/stores/userStore/hooks/useTheme'
import { t } from 'i18next'

export function NoResult(p: NoResultProps) {
  const { theme } = useTheme()

  const props = mergeProps(p, {
    image: 'default',
    type: 'search'
  })
  const emptyIcon = useMemo(() => {
    switch (props.type) {
      case 'list':
        return theme == 'light'
          ? '/assets/imgs/noresult/list-light.svg'
          : '/assets/imgs/noresult/list-dark.svg'
      default:
        return theme == 'light'
          ? '/assets/imgs/noresult/light.svg'
          : '/assets/imgs/noresult/dark.svg'
    }
  }, [theme, props.type])

  const renderImage = () => {
    const { image } = props

    if (isValidElement(image)) {
      return image
    }

    return <img src={emptyIcon} alt="empty" />
  }

  const renderDescription = () => {
    return <div>{props?.emptyText || t('tg_wallet.no_result')}</div>
  }

  return (
    <div
      className={classNames(
        'flex flex-col items-center justify-center space-y-4 text-t3 font-normal text-sm size-full',
        props.className
      )}
      style={props.style}
    >
      <div className="size-[100px]" style={getSizeStyle(props.imageSize)}>
        {renderImage()}
      </div>

      {renderDescription()}
    </div>
  )
}
