import {
  btcCreateSendBtcPsbt,
  btcSignMessage,
  btcSignPsbt,
  btcSignPsbtAndPush,
  decodeInfoByHex
} from '../api'
import {
  BtcCreateSendBtcPsbt,
  BTCNetworkAddressType,
  BTCNetworkType,
  BtcSignPsbt
} from '../api/type'
import * as bitcoin from 'bitcoinjs-lib'
import axios from 'axios'
import { sentryInterceptors } from '@/utils/sentry/axiosInterceptors'
// https://mempool.space/api
export const mockBtcEvmChainId = 0
export const btcDecimals = 8
export const btcScan = `${import.meta.env.VITE_MEMPOOL_API}/tx/`
const endpoint = import.meta.env.VITE_TOMO_BTC_RPC

const api = axios.create({
  baseURL: endpoint
})
sentryInterceptors(api)
export const signMessage = async (params: {
  addressType: string
  message: string
}) => {
  return await btcSignMessage({
    networkType: 'SIGNET',
    addressType: params.addressType,
    message: params.message
  })
  //res.message
}

export const sendTx = async ({
  network,
  addressType,
  toAddress,
  amount
}: {
  network: BTCNetworkType
  addressType: BTCNetworkAddressType | undefined
  toAddress: string
  amount: string
}) => {
  const params: BtcCreateSendBtcPsbt = {
    networkType: network,
    addressType,
    toAddress,
    amount
  }
  const txInfo = await btcCreateSendBtcPsbt(params)
  /**
   * if (txInfo.code == 10000 && txInfo.message === "Success") {
   *       setInputs(txInfo.result.decodeInfo.inputInfos);
   *       setOutputs(txInfo.result.decodeInfo.outputInfos);
   *       setPsbtHex(txInfo.result.psbtHex);
   *       setFee(txInfo.result.decodeInfo.fee);
   *       setFeeRate(txInfo.result.decodeInfo.feeRate);
   *     }
   */

  return txInfo
}

export const getSignTxInfoByHex = async ({
  network,
  psbtHex
}: {
  network: string
  psbtHex: string
}) => {
  const params = {
    networkType: network.toUpperCase(),
    psbtHex: psbtHex + ''
  }
  const txInfo = await decodeInfoByHex(params)
  /**
   * if (txInfo.message === "Success") {
   *       setInputs(txInfo.result.inputInfos);
   *       setOutputs(txInfo.result.outputInfos);
   *       setPsbtHex(txInfo.result.psbtHex);
   *       setFee(txInfo.result.fee);
   *       setFeeRate(txInfo.result.feeRate);
   *
   *       const curAddress = txInfo.result.inputInfos[0].address;
   *       const totalOutputsValue = txInfo.result.outputInfos.reduce(
   *         (acc: any, cur: any) => {
   *           return cur.address !== curAddress ? acc + cur.value : acc;
   *         },
   *         0
   *       );
   *       setSendAmount(satsToBtc(totalOutputsValue));
   *     }
   */
  return txInfo
}

export const sendPsbtTx = async ({
  networkType,
  addressType,
  psbtHex
}: {
  networkType: string
  addressType: string
  psbtHex: string
}) => {
  const params: BtcSignPsbt = {
    networkType: networkType.toUpperCase(),
    addressType,
    autoFinalized: true,
    psbtHex: psbtHex
  }
  const signAndPushRes = await btcSignPsbtAndPush(params)
  return signAndPushRes
  // if (signAndPushRes.message === 'Success') {
  //   handleUpdateSignData(
  //     true,
  //     signAndPushRes.result,
  //     'Transaction sent',
  //     'success',
  //     true
  //   )
  // }
}

export const signPsbt = async ({
  networkType,
  addressType,
  psbtHex
}: {
  networkType: string
  addressType: string
  psbtHex: string
}) => {
  const params: BtcSignPsbt = {
    networkType: networkType.toUpperCase(),
    addressType,
    autoFinalized: true,
    psbtHex: psbtHex
  }
  const signRes = await btcSignPsbt(params)
  return signRes
}

export const getBTCSendHash = (psbtHex: string) => {
  // 解析PSBT
  const psbtHaxTest =
    '70736274ff0100730200000001c3a727f5f05aa5e4e18bee6fb604a21928371295d274c4739afbf308fb110b660000000000ffffffff02102700000000000017a914772f6a083785c0f06a647a90ecb39e57e64ef73787ce7607000000000017a914bb377855e1d01a2692339bcf823d5cf39b3712cf87000000000001012020a107000000000017a914bb377855e1d01a2692339bcf823d5cf39b3712cf87010717160014eb4da06dc422e023e6245378cfbc28a1fdb8f749000000'
  // const psbt = bitcoin.Psbt.fromHex(psbtHex)
  const psbt = bitcoin.Psbt.fromHex(psbtHaxTest)

  // 提取最终的交易
  const extractedTx = psbt.extractTransaction()

  // 获取交易哈希（txid）
  const txid = extractedTx.getId()

  return txid
}

export const getBtcTransactionStatus = async (hash: string) => {
  const res = await api.get(`/api/tx/${hash}`)
  return res.data
}
