import { CurrencyItem, QuoteSide } from '@/api/ramp/type'
import { NoResult } from '@/components/NoResult'
import Search from '@/components/Search'
import { marketChain } from '@/proviers/web3Provider/chains'
import rampStore from '@/stores/rampStore'
import { BackButton } from '@vkruglikov/react-telegram-web-app'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCountryIcon } from './utils'
import { TSearch } from '@/components/tmd'

export const SelectCurrency = observer(() => {
  const navigate = useNavigate()
  const selectedToken = rampStore.selectedToken
  const type = rampStore.selectedTab
  const currencyList =
    type === QuoteSide.BUY
      ? rampStore.buyCurrencyList
      : rampStore.sellCurrencyList
  const [filterCurrency, setFilterCurrency] =
    useState<CurrencyItem[]>(currencyList)

  return (
    <div className="w-full p-4">
      <BackButton onClick={() => navigate(-1)} />
      <TSearch
        type="change"
        onChange={(e) => {
          setFilterCurrency(
            currencyList.filter(
              (item: CurrencyItem) =>
                item.currency.toLowerCase().includes(e.toLowerCase()) ||
                item.country.toLowerCase().includes(e.toLowerCase())
            )
          )
        }}
        placeholder="Search here..."
      />
      <div className="no-scrollbar mt-2 flex max-h-[77vh] flex-col overflow-y-auto">
        {filterCurrency && filterCurrency.length > 0 ? (
          filterCurrency?.map((currency: CurrencyItem, index: number) => {
            const FlagIcon = getCountryIcon(currency.country)
            return (
              <div
                key={`currency_${index}`}
                className="flex items-center gap-2 py-4"
                onClick={() => {
                  const cryptoDetail = rampStore.supportCryptoList.find(
                    (item) =>
                      item.crypto === selectedToken.symbol &&
                      item.network ===
                        marketChain[rampStore.selectedToken?.chainId].chain &&
                      item.buyEnable
                  )
                  if (type === QuoteSide.BUY) {
                    rampStore.setBuySelectedCurrency({
                      ...currency,
                      cryptoDetail
                    })
                  } else {
                    rampStore.setSellSelectedCurrency({
                      ...currency,
                      cryptoDetail
                    })
                  }
                  navigate(-1)
                }}
              >
                <div className="flag-icon size-9">
                  <FlagIcon />
                </div>

                <div className="flex flex-col gap-[2px]">
                  <div className="text-base font-medium text-t1">
                    {currency.country}
                  </div>
                  <div className=" text-xs text-t2">{currency.currency}</div>
                </div>
              </div>
            )
          })
        ) : (
          <NoResult
            emptyText="No result"
            containerClassName="mt-[11rem]"
            textClassName="!text-t3"
          />
        )}
      </div>
    </div>
  )
})
