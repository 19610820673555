import {
  forwardRef,
  useRef,
  useState,
  useEffect,
  useImperativeHandle
} from 'react'
import { Popover as APopover, Mask as AMask } from 'antd-mobile'
import { TooltipRef, TooltipProps } from './PropsType'
import { mergeProps } from '../utils/get-default-props'
import classNames from 'classnames'

// Encapsulating components with forwardRef
const Tooltip = forwardRef<TooltipRef, TooltipProps>((p, ref) => {
  const styles = mergeProps(p.style, { '--content-padding': '18px 16px' })
  const props = mergeProps(p, {})

  const {
    className,
    style,
    children,
    visible: defaultVisible,
    ...restProps
  } = props
  const popoverRef = useRef<TooltipRef | null>(null)
  const [visible, setVisible] = useState(defaultVisible || false)

  useEffect(() => {
    setVisible(defaultVisible || false)
  }, [defaultVisible])

  useImperativeHandle(
    ref,
    () => ({
      hide: () => {},
      show: () => {},
      visible: visible
    }),
    [visible]
  )

  return (
    <>
      <AMask
        visible={visible}
        getContainer={() => document.body}
        color="black"
        opacity={0.7}
        {...restProps}
      />
      <APopover
        {...restProps}
        className={classNames('tmd-tooltip', className)}
        ref={popoverRef}
        style={styles}
        visible={visible}
      >
        {children}
      </APopover>
    </>
  )
})
Tooltip.displayName = 'Tooltip'

export default Tooltip
