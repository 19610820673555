import { useMemo } from 'react'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import { getChainByChainId } from '@/stores/walletStore/utils'
import { observer } from 'mobx-react-lite'
import EthOriginalSvg from '@/assets/imgs/eth-original.svg?react'
import TonOriginalSvg from '@/assets/imgs/chains/ton.svg?react'
import BaseAvatar from '@/components/BaseAvatar'
import { nativeTokenFilter, NativeTokenSymbol } from '@/utils/tokenIcon'
import { TTokenImage } from '@/components/tmd'

interface TokenUIImgType {
  symbol: string
  image: string | undefined
  chainId: number
  isNative: boolean | undefined
  symbolSize?: number
  chainSize?: number
  chainImg?: boolean
  hideChainImg?: boolean
}

export const getTokenImg = (symbol: NativeTokenSymbol, symbolSize: number) => {
  switch (symbol) {
    case NativeTokenSymbol.ETH:
      return <EthOriginalSvg className={`size-[${symbolSize}px]`} />
    case NativeTokenSymbol.TON:
      return <TonOriginalSvg className={`size-[${symbolSize}px]`} />
    default:
      break
  }
}

const TokenImg = observer(
  ({
    symbol,
    image,
    chainId,
    isNative = false,
    symbolSize = 36,
    chainSize = 16,
    hideChainImg
  }: TokenUIImgType) => {
    const filterSymbol = nativeTokenFilter({
      isNative,
      symbol,
      chainId
    })

    const chainImage = useMemo(() => {
      if (hideChainImg) {
        return undefined
      }
      if (typeof chainId !== 'number') {
        return undefined
      }
      if (filterSymbol) {
        return getChainByChainId(chainId ?? -1)?.icon
      }
      if (isNative) {
        return undefined
      }
      return getChainByChainId(chainId ?? -1)?.icon
    }, [isNative, filterSymbol, chainId])

    return (
      <TTokenImage
        image={image || ''}
        size={symbolSize}
        chainImage={chainImage}
        chainSize={chainSize}
        symbol={symbol}
        lazy
      />
    )
  }
)
export default TokenImg
