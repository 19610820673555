import { QuoteRes, QuoteSide } from '@/api/ramp/type'
import { arrowSvgs } from '@/assets'
import AdaptiveNumber, { NumberType } from '@/components/AdaptiveNumber'
import { TIcon } from '@/components/tmd'
import rampStore from '@/stores/rampStore'
import { classNames } from '@/utils'
import BigNumber from 'bignumber.js'
import { useState } from 'react'

interface IProps {
  data: QuoteRes
  type: QuoteSide
  amount: string
}
const Order: React.FC<IProps> = ({ data, type, amount }) => {
  const sellSelectedCurrency = rampStore.sellSelectedCurrency
  const buySelectedCurrency = rampStore.buySelectedCurrency
  const selectToken = rampStore.selectedToken
  const [showMore, setShowMore] = useState<boolean>(false)
  return (
    <div className="mt-6 flex flex-col rounded-lg border border-l1 p-3">
      <div className="flex w-full items-center justify-between">
        <div className="w-[80px] min-w-[80px]">
          <span className="text-base font-medium text-t3">Your order</span>
        </div>
        <div
          onClick={() => setShowMore(!showMore)}
          className="w-[calc(100% -	95px)] flex flex-wrap items-center justify-end gap-[6px] text-xs font-medium text-t1"
        >
          <AdaptiveNumber
            type={NumberType.BALANCE}
            value={amount}
            decimalSubLen={4}
            className="text-right text-base font-medium text-t1"
          />
          <span className="ml-[4px]">
            {type === QuoteSide.BUY
              ? buySelectedCurrency.currency
              : selectToken.symbol}
          </span>

          <span className="text-xs font-medium text-t3">for</span>
          <span>
            <AdaptiveNumber
              type={NumberType.BALANCE}
              value={
                type === QuoteSide.BUY
                  ? data.cryptoQuantity ?? '0'
                  : new BigNumber(data.fiatQuantity ?? 0)
                      .minus(new BigNumber(data.rampFee))
                      .toString()
              }
              decimalSubLen={4}
              className="text-right text-base font-medium text-t1"
            />
            <span className="ml-[4px]">
              {type === QuoteSide.BUY
                ? selectToken.symbol
                : sellSelectedCurrency?.currency}
            </span>
          </span>
        </div>
        <div className="flex" onClick={() => setShowMore(!showMore)}>
          {showMore ? (
            <TIcon name="tg_wallet_pull-up" className="size-4" />
          ) : (
            <TIcon name="tg_wallet_pull-down1" className="size-4" />
          )}
        </div>
      </div>
      {showMore && (
        <div className="mt-3 flex flex-col gap-4 text-sm text-t3">
          <div className="flex items-center justify-between">
            <span>1 {selectToken.symbol}</span>
            <span>{`≈${data.cryptoPrice} ${data.fiat}`}</span>
          </div>
          <div className="flex items-center justify-between">
            <span>Processing fee</span>
            <span>as low as {`${data.rampFee} ${data.fiat}`}</span>
          </div>
          {type === QuoteSide.BUY && (
            <div className="flex items-center justify-between">
              <span>Network fee</span>
              <span>{`${data.networkFee} ${selectToken.symbol}`}</span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Order
