import classNames from 'classnames'
import { SafeAreaIosProps } from './PropsType'
import { isIOS } from '../utils/validate'

export const SafeAreaIos = (props: SafeAreaIosProps) => {
  const { className, ...restProps } = props
  return (
    <div
      className={classNames('flex-none', { 'pb-[34px]': isIOS() }, className)}
      {...restProps}
    ></div>
  )
}
