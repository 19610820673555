import { noSupportImg } from '@/assets'
import { TIcon, TButton } from '@/components/tmd'
import usePopup from '@/hooks/popup/usePopup'

export const useUnsupportPopup = () => {
  const onClose = () => {
    setUnsupportPopupOpen(false)
  }

  const { setOpen: setUnsupportPopupOpen, component: unsupportPopup } =
    usePopup({
      title: '',
      showCloseButton: true,
      maskGesture: true,
      closeIcon: <TIcon name="tg_wallet_disable text-t1" fontSize="20" />,
      fullscreen: true,
      contentClassName: '!pt-[0px] overflow-hidden',
      iconClassName: '!bg-bg5',
      bodyStyle: {
        '--adm-color-background': 'var(--background-BG4)'
      },
      onClose: onClose,
      content: (
        <div className="flex size-full flex-col justify-between ">
          <div className="flex flex-col items-center justify-center text-center text-xl font-semibold text-t1">
            <img src={noSupportImg} alt="" className="mb-4 size-[150px]" />
            <span>This token are not currently supported.</span>
          </div>
          <TButton
            size="large"
            onClick={onClose}
            color="primary"
            className="text-base font-medium"
          >
            OK
          </TButton>
        </div>
      )
    })
  return { setUnsupportPopupOpen, unsupportPopup }
}
