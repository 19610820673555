import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { bitlayer } from '@wagmi/core/chains'

const icon = chainsSvgs.bitlayer
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...bitlayer
  },
  id: 200_901,
  type: 'EVM',
  name: 'Bitlayer Mainnet',
  icon: icon,
  networkType
}

export default chainInfo
