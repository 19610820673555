import {
  IHistoryType,
  IOKXHistoryType,
  ReportHistoryType,
  ReportSourceType
} from '@/state'
import { CustomListInfo, WhiteListInfo } from '@/api/type'
import { APIToken } from './type/APIToken'
import { getCache, STORAGE_KEY } from '@/utils/cacheManage'
import { AssetsToken } from './type/AssetsToken'
import { IWeb3ChainType } from '@/proviers/web3Provider/type'
import { TransactionsType } from '../walletStore/type'
import { UserType } from '../userStore/type'
import { getChainByChainId } from '../walletStore/utils'
import chains, { UNSUPPROT_HISTORY_CHAIN } from '@/proviers/web3Provider/chains'
import { SUI_TYPE_ARG } from '@mysten/sui/utils'
import { BigNumber } from 'bignumber.js'
import { initUserInfo, userChainAddressList } from '../userStore/utils'
import { OkxChainIdToMockIdMap } from '@/config/tron'
import tokenStore from '.'
import dayjs from 'dayjs'

import { groupByDate } from '@/stores/walletStore/utils'

BigNumber.config({
  FORMAT: {
    prefix: '',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: ' ',
    fractionGroupSize: 0,
    suffix: ''
  },
  EXPONENTIAL_AT: [-18, 30]
})

export function mergeTokensData({
  whiteTokens,
  customTokens
}: {
  whiteTokens: WhiteListInfo[]
  customTokens: CustomListInfo[]
}): APIToken[] {
  // Filter out the chain_id and token combinations in customQueryData that already exist in queryData
  const filteredCustomQueryData: APIToken[] =
    customTokens
      ?.filter((n: CustomListInfo) => {
        return !whiteTokens.find(
          (m: WhiteListInfo) =>
            m.contract?.toLocaleUpperCase() === n.token.toLocaleUpperCase() &&
            m.chain_id === n.chain_id
        )
      })
      .map(
        (i) =>
          ({
            isNative: !i.token,
            isToken: !!i.token,
            chainId: i.chain_id,
            decimals: i.decimals,
            symbol: i.symbol,
            name: i.name,
            address: i.token,
            balance: '0', //not trust
            price: i.price,
            image: i.image,
            source: 'custom',
            whiteToken: undefined,
            customToken: i
          }) as APIToken
      ) || []

  // Formatting queryData data
  const formattedQueryData =
    whiteTokens?.map((i) => {
      const symbol = i.symbol.includes('ETH') ? 'ETH' : i.symbol
      return {
        isNative: i.is_native,
        isToken: !i.is_native,
        chainId: i.chain_id,
        decimals: i.decimals,
        symbol,
        name: i.name,
        address: i.contract,
        balance: '0', //not trust
        price: i.price,
        image: i.image,
        source: 'all',
        whiteToken: i,
        customToken: undefined
      } as APIToken
    }) || []

  return [...formattedQueryData, ...filteredCustomQueryData]
}

export function shallowEqual(arr1: any[], arr2: any[]) {
  if (arr1 === arr2) return true
  if (arr1.length !== arr2.length) return false
  return arr1.every((item, index) => item === arr2[index])
}

export function shallowAssetsTokenEqual(arr1: any[], arr2: any[]) {
  if (arr1 === arr2) return true
  if (arr1.length !== arr2.length) return false
  if (arr1.length === 0) return false
  let flag = 0
  arr1.forEach((asset1, idx) => {
    const assets2 = arr2[idx]
    if (!assetsTokenEqual(asset1, assets2)) {
      flag++
    }
  })
  return flag === 0
}
export function shallowCustomListInfoEqual(arr1: any[], arr2: any[]) {
  if (arr1 === arr2) return true
  if (arr1.length !== arr2.length) return false
  if (arr1.length === 0) return false
  let flag = 0
  arr1.forEach((asset1, idx) => {
    const assets2 = arr2[idx]
    if (!customListInfoEqual(asset1, assets2)) {
      flag++
    }
  })
  return flag === 0
}
export function shallowWhiteListInfoEqual(arr1: any[], arr2: any[]) {
  if (arr1 === arr2) return true
  if (arr1.length !== arr2.length) return false
  if (arr1.length === 0) return false
  let flag = 0
  arr1.forEach((asset1, idx) => {
    const assets2 = arr2[idx]
    if (!whiteListInfoEqual(asset1, assets2)) {
      flag++
    }
  })
  return flag === 0
}

const assetsTokenEqual = (a: AssetsToken, b: AssetsToken) => {
  return (
    a.isNative === b.isNative &&
    a.isToken === b.isToken &&
    a.chainId === b.chainId &&
    a.decimals === b.decimals &&
    a.symbol === b.symbol &&
    a.name === b.name &&
    a.address === b.address &&
    a.balance === b.balance &&
    a.price === b.price &&
    a.image === b.image &&
    a.formatted === b.formatted &&
    a.customToken?.price_change_h24 === b.customToken?.price_change_h24
  )
}

const customListInfoEqual = (a: CustomListInfo, b: CustomListInfo) => {
  return (
    a.ID === b.ID &&
    a.chain_id === b.chain_id &&
    a.decimals === b.decimals &&
    a.image === b.image &&
    a.market_cap === b.market_cap &&
    a.name === b.name &&
    a.price === b.price &&
    a.price_change_h24 === b.price_change_h24 &&
    a.symbol === b.symbol &&
    a.token === b.token &&
    a.uid === b.uid
  )
}

const whiteListInfoEqual = (a: WhiteListInfo, b: WhiteListInfo) => {
  return (
    a.chain_id === b.chain_id &&
    a.contract === b.contract &&
    a.decimals === b.decimals &&
    a.image === b.image &&
    a.is_native === b.is_native &&
    a.mercuryo_support === b.mercuryo_support &&
    a.name === b.name &&
    a.price === b.price &&
    a.ramp_support === b.ramp_support &&
    a.symbol === b.symbol
  )
}

export const getWalletTokensKey = () => {
  let walletId = null
  const userStr = localStorage.getItem(STORAGE_KEY.user)
  if (userStr) {
    const userInfo = JSON.parse(userStr)
    walletId = userInfo['defaultWalletId'] ?? -1
    return `${STORAGE_KEY.TOKENS_LIST}_${walletId}`
  }
  return null
}

export const getCacheTokens = () => {
  const list = getCache(getWalletTokensKey() as string) as AssetsToken[]
  if (list?.length) {
    return list.filter((i) => i.address !== SUI_TYPE_ARG)
  }
  return []
}

export const getNativeToken = (
  tokenList: AssetsToken[],
  iChain?: IWeb3ChainType | undefined
) => {
  if (!iChain) {
    return tokenList.filter((token) => token.isNative)[0]
  }
  return tokenList.find(
    (token) => token.isNative && token.chainId === iChain.id
  )
}

export const getBalance = (
  tokenList: AssetsToken[],
  chainId: number,
  address: string
) =>
  tokenList.find(
    (token) =>
      token.address.toLocaleUpperCase() === address.toLocaleUpperCase() &&
      token.chainId === chainId
  )

export const getReceiveToken = (
  source: AssetsToken[],
  chainId: number | undefined,
  symbol: string | undefined,
  address: string | undefined
) => {
  return source.find((token) => {
    const polFlag = symbol === 'MATIC' && token.symbol === 'POL'
    return (
      token.chainId === chainId &&
      (token.symbol.toLowerCase() === symbol?.toLowerCase() || polFlag) &&
      token.address.toLowerCase() === address?.toLowerCase()
    )
  })
}

export const addChainIdToApiReq = (source: any[]) => {
  if (!source || source.length === 0) return source
  return [...source].map((tread) => {
    // tread.chainId = (CHAIN_MAPS_ID as any)[tread.chain] ?? -1
    // tread.chainId = (CHAIN_MAPS_ID as any)[tread.chain] ?? -1
    tread.chainId = getWalletTokenChainId({ chain: tread.chain })
    return tread
  })
}

export const sameHashMerge = (list: IOKXHistoryType[]) => {
  const mergedTxs: IOKXHistoryType[][] = []
  let cacheList: IOKXHistoryType[] = []
  list.forEach((i, idx) => {
    if (!cacheList.length) {
      cacheList.push(i)
      if (idx === list.length - 1) {
        mergedTxs.push([...cacheList])
      }
      return
    }
    const last = cacheList[cacheList.length - 1]
    if (last.txHash === i.txHash) {
      cacheList.push(i)
      if (idx === list.length - 1) {
        mergedTxs.push([...cacheList])
      }
      return
    }
    mergedTxs.push([...cacheList])
    cacheList = []
    cacheList.push(i)
    if (idx === list.length - 1) {
      mergedTxs.push([...cacheList])
    }
  })
  return mergedTxs
}

export const mergeOkxHistory = (
  list: IOKXHistoryType[],
  user: UserType,
  reports: ReportHistoryType[],
  tokenList: AssetsToken[]
) => {
  const methods = {
    '0x9871efa4': 'unxswapByOrderId',
    '0xa9059cbb': 'transfer',
    '0xb80c2f09': 'smartSwapByOrderId',
    '0x972250fe': '',
    '0x095ea7b3': 'approve',
    '0x3d21e25a': 'swapBridgeToV2'
  }

  const mergedOKXTxs = sameHashMerge(list)
  const txsFlatOKX = mergedOKXTxs
    .filter(
      (i) =>
        i[i.length - 1].tag !== 'Risk Airdrop' && !i[i.length - 1].hitBlacklist
    )
    .filter((i) => i[0].methodId !== '0x095ea7b3') //TODO, hidden Approve
    .map((i: IOKXHistoryType[]) =>
      methodsToHistory(i, user, reports, tokenList)
    )

  const pendingReports = reports
    .filter((i) => i.source.includes('pending'))
    .filter((i) => !JSON.stringify(list).includes(i.tx))
    .map((i) => ReportSourcePendingToIHistoryType(i, tokenList))
  const unOkxReports = reports
    .map((i) => ReportSourcePendingToIHistoryType(i, tokenList))
    .filter((j: IHistoryType) =>
      UNSUPPROT_HISTORY_CHAIN.find((id) => id === j.chain?.id)
    )

  //find pending txs
  const mergedPendingReports = pendingReports.filter(
    (i) => !unOkxReports.find((j) => j.hash === i.hash)
  )
  const pendingTxs = mergedPendingReports.filter(
    (i) => !txsFlatOKX.find((j) => j.hash === i.hash)
  )
  const repList = [...unOkxReports, ...pendingTxs]

  const groupOkx = groupByDate(txsFlatOKX)
  const groupRep = groupByDate(repList)

  const groupOkxKey = Object.keys(groupOkx)
    .map((i) => dayjs(i).valueOf())
    .sort((a, b) => a - b)

  const groupRepFilter = Object.keys(groupRep)
    .filter((i) => dayjs(i).valueOf() >= groupOkxKey[0])
    .map((i) => groupRep[i])
    .flat()

  const mergeAll: IHistoryType[] = [...groupRepFilter, ...txsFlatOKX]
  const totalTxs = mergeAll.sort((a, b) => b.time - a.time)
  const txs: TransactionsType = txListToTransactionsType(totalTxs)
  return {
    txs,
    txsFlat: totalTxs
  }
}

const methodsToHistory = (
  list: IOKXHistoryType[],
  user: UserType,
  reports: ReportHistoryType[],
  tokenList: AssetsToken[]
) => {
  const first = list[0]
  const last = list[list.length - 1]
  const status = first.txStatus
  const endTime = first.txTime
  const networkFee = first.txFee
  const gasAmount = first.txFee
  const hash = first.txHash
  const chainId =
    (OkxChainIdToMockIdMap as any)[first.chainIndex] ?? Number(first.chainIndex)
  const lastChainId =
    (OkxChainIdToMockIdMap as any)[last.chainIndex] ?? Number(last.chainIndex)
  const nonce = first.nonce

  const result: IHistoryType = {} as any as IHistoryType
  result.fromAddress = first.from[0].address
  result.nonce = Number(nonce)
  result.networkFee = networkFee
  result.hash = hash
  if (endTime.length > 13) {
    result.endTime = Number(endTime.slice(0, 13))
  } else {
    result.endTime = Number(endTime)
  }
  result.time = result.endTime
  result.gasAmount = gasAmount
  result.chain = getChainByChainId(Number(chainId))
  result.status = status as any

  const find = reports.find(
    (i) => i.tx.toLowerCase() === first.txHash.toLowerCase()
  )
  if (find) {
    // base use
    result.historyType = find.type.toUpperCase() === 'SEND' ? 'Send' : 'Swap'

    // noraml
    try {
      if (find.source) {
        const dataJSON: ReportSourceType = JSON.parse(find.source)
        result.fromAmount = dataJSON.from.amount
        result.toAmount = dataJSON.to.amount
        let isNativeFrom = !dataJSON.from.tokenAddress
        if (dataJSON.from.chainID === chains.sui.id) {
          if (dataJSON.from.tokenAddress === SUI_TYPE_ARG) {
            isNativeFrom = true
          }
        }
        const findFromToken = tokenList.find(
          (o) =>
            o.address.toLowerCase() ===
              dataJSON.from.tokenAddress.toLowerCase() &&
            o.chainId === dataJSON.from.chainID
        )
        let fromToken: AssetsToken = {
          isNative: isNativeFrom,
          isToken: !isNativeFrom,
          chainId: dataJSON.from.chainID,
          decimals: dataJSON.from.decimals,
          symbol: dataJSON.from.symbol,
          name: dataJSON.from.symbol,
          address: dataJSON.from.tokenAddress,
          balance: '',
          price: 0,
          image: '',
          source: 'all',
          id: `${dataJSON.from.tokenAddress}-${dataJSON.from.chainID}-${dataJSON.from.symbol}`,
          formatted: ''
        }
        if (findFromToken) {
          fromToken = findFromToken
        }
        result.fromSwapTokens = {
          token: fromToken,
          chain: getChainByChainId(dataJSON.from.chainID),
          balance: undefined
        }
        let isNativeTo = !dataJSON.to.tokenAddress
        if (dataJSON.to.chainID === chains.sui.id) {
          if (dataJSON.to.tokenAddress === SUI_TYPE_ARG) {
            isNativeTo = true
          }
        }
        const findToToken = tokenList.find(
          (o) =>
            o.address.toLowerCase() ===
              dataJSON.to.tokenAddress.toLowerCase() &&
            o.chainId === dataJSON.to.chainID
        )
        let toSwapToken: AssetsToken = {
          isNative: isNativeTo,
          isToken: !isNativeTo,
          chainId: dataJSON.to.chainID,
          decimals: dataJSON.to.decimals,
          symbol: dataJSON.to.symbol,
          name: dataJSON.to.symbol,
          address: dataJSON.to.tokenAddress,
          balance: '',
          price: 0,
          image: '',
          source: 'all',
          id: `${dataJSON.to.tokenAddress}-${dataJSON.to.chainID}-${dataJSON.to.symbol}`,
          formatted: ''
        }
        if (findToToken) {
          toSwapToken = findToToken
        }
        result.toSwapTokens = {
          token: toSwapToken,
          chain: getChainByChainId(dataJSON.to.chainID),
          balance: undefined
        }
        result.time = dataJSON.time
        result.type = dataJSON.plat
        result.requestId = dataJSON.requestId
        result.status =
          dataJSON.sourceType == 'normal' ? (status as any) : 'pending'

        if (dataJSON.sourceType === 'cross') {
          if (dataJSON.toHash) {
            result.status = 'success'
          }
          if (dataJSON.status !== 'pending') {
            result.status = dataJSON.status as any
          }
        }

        result.routeInfo = dataJSON.routeInfo
        result.source = 'TOMO'
        if (result.historyType === 'Send') {
          result.toAddress = last.to[0].address || dataJSON.toAddress
        }
        return result
      }
    } catch (e) {
      console.log('source json error')
    }
  }

  // Approve
  if (first.methodId === '0x095ea7b3') {
    result.historyType = 'Approve'
    result.fromAddress = first.from[0].address
    result.toAddress = ''
    result.fromAmount = ''
    result.toAmount = ''
    result.networkFee = networkFee
    const findToken = tokenList.find(
      (o) =>
        o.address.toLowerCase() === last.tokenAddress.toLowerCase() &&
        o.chainId === chainId
    )
    //!TODO ...should check symbol and amount in chain
    let token: AssetsToken = {
      isNative: false,
      isToken: true,
      chainId: chainId,
      decimals: 0,
      symbol: '',
      name: '',
      address: last.tokenAddress,
      balance: '',
      price: 0,
      image: '',
      source: 'all',
      id: `${last.tokenAddress}-${chainId}-${last.symbol}`,
      formatted: ''
    }
    if (findToken) {
      token = findToken
    }
    result.fromSwapTokens = {
      token,
      chain: undefined,
      balance: undefined
    }
    result.toSwapTokens = {
      token,
      chain: undefined,
      balance: undefined
    }
    result.fromAmount = ''
    result.toAmount = ''
    result.source = 'OKX'
    return result
  }

  //other methodID or empty methodID check if is swap
  let isSwap = false
  if (list.length === 2) {
    isSwap =
      list[0].amount !== '0' &&
      list[0].symbol !== list[1].symbol &&
      !!list[0].methodId
  }
  if (list.length > 2) {
    if (list[0].amount !== '0') {
      isSwap =
        list[0].symbol !== list[list.length - 1].symbol && !!list[0].methodId
    } else {
      isSwap =
        list[1].symbol !== list[list.length - 1].symbol && !!list[0].methodId
    }
  }
  //check if address is user
  const fromList = list.filter(
    (i) => i.amount !== '0' && checkIfAddressUser(i.from[0].address, user)
  )
  const toList = list.filter(
    (i) => i.amount !== '0' && !checkIfAddressUser(i.from[0].address, user)
  )
  //check swap again
  if (isSwap && toList.length === 0) {
    isSwap = false
  }

  if (isSwap) {
    const fromChainId =
      (OkxChainIdToMockIdMap as any)[fromList[0].chainIndex] ??
      Number(fromList[0].chainIndex)

    const findFromToken = tokenList.find(
      (o) =>
        o.address.toLowerCase() === fromList[0].tokenAddress.toLowerCase() &&
        o.chainId === fromChainId
    )

    let fromToken: AssetsToken = {
      isNative: !fromList[0].tokenAddress,
      isToken: !!fromList[0].tokenAddress,
      chainId: fromChainId,
      decimals: 0,
      symbol: fromList[0].symbol,
      name: fromList[0].symbol,
      address: fromList[0].tokenAddress,
      balance: '',
      price: 0,
      image: '',
      source: 'all',
      id: `${fromList[0].tokenAddress}-${fromChainId}-${fromList[0].symbol}`,
      formatted: ''
    }
    if (findFromToken) {
      fromToken = findFromToken
    }
    let fromNum = BigNumber('0')
    for (let j = 0; j < fromList.length; j++) {
      fromNum = fromNum.plus(fromList[j].amount)
    }
    result.fromAmount = fromNum.toString()

    const toChainId =
      (OkxChainIdToMockIdMap as any)[toList[0].chainIndex] ??
      Number(toList[0].chainIndex)

    const findToToken = tokenList.find(
      (o) =>
        o.address.toLowerCase() === toList[0].tokenAddress.toLowerCase() &&
        o.chainId === toChainId
    )
    let toToken: AssetsToken = {
      isNative: !toList[0].tokenAddress,
      isToken: !!toList[0].tokenAddress,
      chainId: toChainId,
      decimals: 0,
      symbol: toList[0].symbol,
      name: toList[0].symbol,
      address: toList[0].tokenAddress,
      balance: '',
      price: 0,
      image: '',
      source: 'all',
      id: `${toList[0].tokenAddress}-${toChainId}-${toList[0].symbol}`,
      formatted: ''
    }
    if (findToToken) {
      toToken = findToToken
    }
    let toNum = BigNumber('0')
    for (let j = 0; j < toList.length; j++) {
      toNum = toNum.plus(toList[j].amount)
    }
    result.toAmount = toNum.toString()
    result.fromSwapTokens = {
      token: fromToken,
      chain: getChainByChainId(fromChainId),
      balance: undefined
    }
    result.toSwapTokens = {
      token: toToken,
      chain: getChainByChainId(toChainId),
      balance: undefined
    }
    result.toAddress = toList[0].to[0].address
    result.historyType = 'Swap'
    result.source = 'OKX'
    return result
  }

  let isNative = !last.tokenAddress
  if (chainId === chains.sui.id) {
    if (last.tokenAddress === SUI_TYPE_ARG) {
      isNative = true
    }
  }

  const findToken = tokenList.find(
    (o) =>
      o.address.toLowerCase() === last.tokenAddress.toLowerCase() &&
      o.chainId === lastChainId
  )
  let token: AssetsToken = {
    isNative,
    isToken: !isNative,
    chainId: lastChainId,
    decimals: 0,
    symbol: last.symbol,
    name: last.symbol,
    address: last.tokenAddress,
    balance: '',
    price: 0,
    image: '',
    source: 'all',
    id: `${last.tokenAddress}-${lastChainId}-${last.symbol}`,
    formatted: ''
  }
  if (findToken) {
    token = findToken
  }
  result.fromSwapTokens = {
    token,
    chain: getChainByChainId(lastChainId),
    balance: undefined
  }
  result.toSwapTokens = {
    token,
    chain: getChainByChainId(lastChainId),
    balance: undefined
  }
  result.toAddress = last.to[0].address
  let amountNum = BigNumber('0')
  for (let j = 0; j < list.length; j++) {
    if (list[j].tokenAddress === last.tokenAddress) {
      amountNum = amountNum.plus(list[j].amount)
    }
  }
  result.fromAmount = amountNum.toString()
  result.toAmount = amountNum.toString()
  // !Warning sui address should test, and other no evm address, eg.
  if (chainId === chains.sui.id) {
    result.historyType =
      last.to[0].address.toLowerCase() === user.suiAddress?.toLowerCase()
        ? 'Receive'
        : 'Send'
  } else {
    result.historyType =
      last.to[0].address.toLowerCase() ===
      userChainAddressList(
        user,
        (result.chain as IWeb3ChainType).id
      ).toLowerCase()
        ? 'Receive'
        : 'Send'
  }
  result.source = 'OKX'
  return result
}

const checkIfAddressUser = (addr: string, user: UserType) => {
  return (
    addr === user.ethereumAddress ||
    addr === user.suiAddress ||
    addr === user.tronAddress ||
    addr === user.solanaAddress
  )
}

export const jsonFilter = (source: string | undefined) => {
  if (!source) return false
  try {
    return !!JSON.parse(source)
  } catch (e) {
    return false
  }
}

export const txListToTransactionsType = (list: IHistoryType[]) => {
  const txs: TransactionsType = {}
  list.forEach((i: IHistoryType) => {
    const chainId = i.chain?.id
    if (!chainId) return
    if (!txs[chainId]) {
      txs[chainId] = []
    }
    txs[chainId].push(i)
  })
  return txs
}

export const ReportSourcePendingToIHistoryType = (
  tx: ReportHistoryType,
  tokenList: AssetsToken[]
) => {
  const result: IHistoryType = {} as any as IHistoryType
  try {
    const dataJSON: ReportSourceType = JSON.parse(tx.source)
    const status = dataJSON.status as any
    const user = initUserInfo()
    const userAddr = userChainAddressList(user, tx.chainID)
    result.fromAddress = userAddr
    result.nonce = 0
    result.networkFee = ''
    result.hash = tx.tx
    result.endTime = 0
    result.gasAmount = ''
    const chainId =
      (OkxChainIdToMockIdMap as any)[tx.chainID] ?? Number(tx.chainID)
    result.chain = getChainByChainId(chainId)

    result.fromAmount = dataJSON.from.amount
    result.toAmount = dataJSON.to.amount
    let isNativeFrom = !dataJSON.from.tokenAddress
    if (dataJSON.from.chainID === chains.sui.id) {
      if (dataJSON.from.tokenAddress === SUI_TYPE_ARG) {
        isNativeFrom = true
      }
    }
    const findFromToken = tokenList.find(
      (o) =>
        o.address.toLowerCase() === dataJSON.from.tokenAddress.toLowerCase() &&
        o.chainId === dataJSON.from.chainID
    )
    let fromToken: AssetsToken = {
      isNative: isNativeFrom,
      isToken: !isNativeFrom,
      chainId: dataJSON.from.chainID,
      decimals: dataJSON.from.decimals,
      symbol: dataJSON.from.symbol,
      name: dataJSON.from.symbol,
      address: dataJSON.from.tokenAddress,
      balance: '',
      price: 0,
      image: '',
      source: 'all',
      id: `${dataJSON.from.tokenAddress}-${dataJSON.from.chainID}-${dataJSON.from.symbol}`,
      formatted: ''
    }
    if (findFromToken) {
      fromToken = findFromToken
    }
    result.fromSwapTokens = {
      token: fromToken,
      chain: getChainByChainId(dataJSON.from.chainID),
      balance: undefined
    }
    let isNativeTo = !dataJSON.to.tokenAddress
    if (dataJSON.to.chainID === chains.sui.id) {
      if (dataJSON.to.tokenAddress === SUI_TYPE_ARG) {
        isNativeTo = true
      }
    }
    const findToToken = tokenList.find(
      (o) =>
        o.address.toLowerCase() === dataJSON.to.tokenAddress.toLowerCase() &&
        o.chainId === dataJSON.to.chainID
    )
    let toSwapToken: AssetsToken = {
      isNative: isNativeTo,
      isToken: !isNativeTo,
      chainId: dataJSON.to.chainID,
      decimals: dataJSON.to.decimals,
      symbol: dataJSON.to.symbol,
      name: dataJSON.to.symbol,
      address: dataJSON.to.tokenAddress,
      balance: '',
      price: 0,
      image: '',
      source: 'all',
      id: `${dataJSON.to.tokenAddress}-${dataJSON.to.chainID}-${dataJSON.to.symbol}`,
      formatted: ''
    }
    if (findToToken) {
      toSwapToken = findToToken
    }
    result.toSwapTokens = {
      token: toSwapToken,
      chain: getChainByChainId(dataJSON.to.chainID),
      balance: undefined
    }
    result.historyType = tx.type === 'swap' ? 'Swap' : 'Send'
    result.time = dataJSON.time
    result.type = dataJSON.plat
    result.requestId = dataJSON.requestId
    result.status =
      dataJSON.sourceType == 'normal' ? (status as any) : 'pending'
    if (dataJSON.sourceType === 'cross') {
      if (dataJSON.toHash) {
        result.status = 'success'
      }
      if (dataJSON.status !== 'pending') {
        result.status = dataJSON.status as any
      }
    }
    if (result.historyType === 'Send') {
      result.toAddress = dataJSON.toAddress || ''
    }
    result.routeInfo = dataJSON.routeInfo
  } catch (error) {
    console.warn('ReportSourcePendingToIHistoryType', error, tx)
  }
  return result
}

export const getWalletToken = ({
  chainId,
  address
}: {
  chainId: number
  address: string
}) => {
  const whiteToken = tokenStore.whiteTokens.find(
    (i) => i.chain_id === chainId && i.contract === address
  )
  const customToken = tokenStore.customTokens.find(
    (i) => i.chain_id === chainId && i.token === address
  )

  return whiteToken || customToken || undefined
}

export const getWalletTokenChainId = ({ chain }: { chain: string }) => {
  const whiteToken = tokenStore.whiteTokens.find((i) => i.chain === chain)
  const customToken = tokenStore.customTokens.find((i) => i.chain === chain)

  return whiteToken
    ? whiteToken?.chain_id
    : customToken
      ? customToken?.chain_id
      : undefined
}

export const getWalletTokenChain = ({ chain }: { chain: string }) => {
  const chainId = getWalletTokenChainId({ chain })

  return typeof chainId === 'number' ? getChainByChainId(chainId) : undefined
}
