import { observable, autorun, action } from 'mobx'
import { CustomListInfo, WhiteListInfo } from '@/api/type'
import { AssetsToken } from './type/AssetsToken'
import {
  getCacheTokens,
  getWalletTokensKey,
  shallowAssetsTokenEqual,
  shallowCustomListInfoEqual,
  shallowEqual,
  shallowWhiteListInfoEqual
} from './util'
import { getCache } from '@/utils/cacheManage'
import { sortByPriceBalance } from '../walletStore/utils'

interface ITokenStore {
  customTokens: CustomListInfo[]
  whiteTokens: WhiteListInfo[]
  tokenList: AssetsToken[]
  tokenListKeys: string
  refreshTime: number
  refreshEVMTime: number
  refreshSolTime: number
  refreshBTCTime: number
  refreshTonTime: number
  refreshTronTime: number
  refreshSuiTime: number
  refreshTokenListTime: number
  isLoading: boolean
  customTokensActions: (tokens: CustomListInfo[]) => void
  whiteTokensActions: (tokens: WhiteListInfo[]) => void
  tokensReSetActions: () => void
  tokensActions: (tokens: AssetsToken[]) => void
  updateLoadingState: (state: boolean) => void
  /**
   *
   * @warning refresh all data of tokenstore.
   */
  refreshTokenStore: (time: number) => void
  /**
   *
   * @warning refresh EVM balance of tokenstore.
   */
  refreshEVMBalance: (time: number) => void
  /**
   *
   * @warning refresh Sol balance of tokenstore.
   */
  refreshSolBalance: (time: number) => void
  /**
   *
   * @warning refresh BTC balance of tokenstore.
   */
  refreshBTCBalance: (time: number) => void
  /**
   *
   * @warning refresh Ton balance of tokenstore.
   */
  refreshTonBalance: (time: number) => void
  /**
   *
   * @warning refresh Tron balance of tokenstore.
   */
  refreshTronBalance: (time: number) => void
  /**
   *
   * @warning refresh token list of tokenstore.
   */
  refreshTokenList: (time: number) => void
}

/**
 *
 * @warning this store only support source api token list.
 */
const tokenStore: ITokenStore = observable({
  customTokens: [],
  whiteTokens: [],
  tokenList: getCacheTokens().length ? getCacheTokens() : [],
  tokenListKeys: '',
  refreshTime: 0,
  refreshEVMTime: 0,
  refreshSolTime: 0,
  refreshBTCTime: 0,
  refreshTonTime: 0,
  refreshTronTime: 0,
  refreshSuiTime: 0,
  refreshTokenListTime: 0,
  isLoading: true,
  customTokensActions: action((tokens: CustomListInfo[]) => {
    if (shallowCustomListInfoEqual(tokenStore.customTokens, tokens)) {
      return
    }
    tokenStore.customTokens = tokens
  }),
  whiteTokensActions: action((tokens: WhiteListInfo[]) => {
    if (shallowWhiteListInfoEqual(tokenStore.whiteTokens, tokens)) {
      return
    }
    tokenStore.whiteTokens = tokens
  }),
  tokensReSetActions: action(() => {
    tokenStore.tokenList = getCache(getWalletTokensKey() as string).length
      ? getCache(getWalletTokensKey() as string)
      : []
  }),
  tokensActions: action((tokens: AssetsToken[]) => {
    if (shallowAssetsTokenEqual(tokenStore.tokenList, tokens)) {
      return
    }
    tokenStore.tokenList = sortByPriceBalance(tokens)
    tokenStore.tokenListKeys = JSON.stringify(tokenStore.tokenList)
  }),
  refreshTokenStore: action((time: number) => (tokenStore.refreshTime = time)),
  refreshEVMBalance: action(
    (time: number) => (tokenStore.refreshEVMTime = time)
  ),
  refreshSolBalance: action(
    (time: number) => (tokenStore.refreshSolTime = time)
  ),
  refreshBTCBalance: action(
    (time: number) => (tokenStore.refreshBTCTime = time)
  ),
  refreshTonBalance: action(
    (time: number) => (tokenStore.refreshTonTime = time)
  ),
  refreshTronBalance: action(
    (time: number) => (tokenStore.refreshTronTime = time)
  ),
  refreshSuiBalance: action(
    (time: number) => (tokenStore.refreshSuiTime = time)
  ),
  refreshTokenList: action(
    (time: number) => (tokenStore.refreshTokenListTime = time)
  ),
  updateLoadingState: action((state: boolean) => {
    tokenStore.isLoading = state
  })
})

autorun(() => {
  console.log({
    key: 'tokenStore',
    customTokens: tokenStore.customTokens,
    whiteTokens: tokenStore.whiteTokens,
    tokenList: tokenStore.tokenList
  })
})

export default tokenStore
