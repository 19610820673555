import useBackup from '@/pages/login/hooks/useBackup'
import { evmSvg } from 'assets'
import { IWeb3ChainType } from 'proviers/web3Provider/type'
import { getBitCoinType, IBtcAddressType } from 'utils'
import { shortenAddress } from 'utils/helper'
import { TIcon, TIconButton, TCopy } from '@/components/tmd'
import toast from '@/components/tmd/toast/Toast'

const ShowAccountItem = (params: {
  chain: IWeb3ChainType
  address: string | undefined
  type: IBtcAddressType | undefined
  openQrcode: (flag: boolean) => void
}) => {
  const handleOpenQRCode = () => {
    params.openQrcode(true)
  }

  const { setOpen, component } = useBackup({
    onSkip: () => {
      setOpen(false)
    }
  })

  return (
    <>
      {component}
      <div className="flex h-[73px] w-full items-center justify-between rounded-[12px] bg-bg1 px-[14px] py-[16px]">
        <div className="flex flex-1 items-center justify-start gap-[8px] overflow-hidden">
          <div className="flex flex-none items-center justify-start gap-[12px]">
            {params.chain.type == 'EVM' ? (
              <img src={evmSvg} className="h-[36px] w-[89px]" />
            ) : (
              <img className="size-[36px]" src={params.chain.icon} />
            )}
          </div>
          <div className="flex flex-col gap-[2px] overflow-hidden">
            <div className="flex items-center">
              <span className="text-base font-medium text-t1">
                {params.chain.type == 'EVM' ? 'EVM' : params.chain.name}
              </span>
              {params.type && (
                <div
                  className={`ml-[4px] flex h-[16px] items-center rounded-[2px] bg-bg3 px-[4px] py-[2px]`}
                >
                  <span className="text-df text-t2">
                    {getBitCoinType(params.type)}
                  </span>
                </div>
              )}
            </div>
            <div className="flex items-center justify-start gap-[4px]">
              <span className="text-xs text-t2">
                {params.address && shortenAddress(params.address, 6, 4)}
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-none gap-4">
          {params.address && (
            <TIconButton className="size-[38px]">
              <TCopy
                text={params.address || ''}
                className="!size-full"
                onCopy={() => toast.success('Copied to clipboard successfully')}
              />
            </TIconButton>
          )}

          <TIconButton className="size-[38px]" onClick={handleOpenQRCode}>
            <TIcon name="tg_wallet_QRcode" className="text-t1" fontSize="18" />
          </TIconButton>
        </div>
      </div>
    </>
  )
}

export default ShowAccountItem
