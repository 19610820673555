import * as Sentry from '@sentry/react'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

interface SentryAxiosRequestConfig extends AxiosRequestConfig {
  __sentrySpan?: Sentry.Span
}

/**
 * Create a Sentry span for the given axios request config.
 *
 * @param config the axios request config
 *
 * The span is created with the following attributes:
 * - op: "http.client"
 * - name: "【{method}】{baseURL}{url}"
 *
 * The span is then stored in the request config at the key `__sentrySpan`.
 */
export function createSentrySpan(config: AxiosRequestConfig = {}) {
  if (!config) return
  const { method, baseURL, url } = config
  const span = Sentry.startInactiveSpan({
    op: 'http.client',
    name: `【${method}】${baseURL}${removeFirstSlash(url)}`
  })
  // set span to request config
  ;(config as SentryAxiosRequestConfig).__sentrySpan = span
}

/**
 * End the Sentry span created by {@link createSentrySpan} with the given axios response.
 *
 * @param response the axios response
 *
 * The span is ended with the following attributes:
 * - status: from the response status code
 * - http.status_code: the response status code
 * - http.res_code: the code in the response data
 * - http.res_msg: the message in the response data
 */
export function endSentrySpan(
  response: AxiosResponse<Record<string, any>>
): void {
  const span = (response?.config as SentryAxiosRequestConfig)?.__sentrySpan
  if (span?.isRecording()) {
    span.setStatus(Sentry.getSpanStatusFromHttpCode(response.status))
    span.setAttribute('http.status_code', response.status)
    if (response?.data) {
      const { code, message } = response.data
      span.setAttribute('http.res_code', code || 'unknown')
      span.setAttribute('http.res_msg', message || 'unknown')
    }
    span.end()
  }
}

/**
 * End the Sentry span created by {@link createSentrySpan} with the given axios error.
 *
 * @param error the axios error
 *
 * The span is ended with the following attributes:
 * - status: from the error response status code
 * - http.err_code: the code in the error response data
 * - http.err_msg: the message in the error response data
 * - http.err_stack: the stack of the error
 */
export function handleSentrySpanError(error: Record<string, any>): void {
  const span = (error?.config as SentryAxiosRequestConfig)?.__sentrySpan
  if (span && span.isRecording()) {
    span.setAttribute('http.err_code', error?.code)
    span.setAttribute('http.err_msg', error?.message)
    span.setAttribute('http.err_stack', error?.stack)
    span.setStatus(Sentry.getSpanStatusFromHttpCode(error?.response?.status))
    span.end()
  }
}

/**
 * Remove the first slash from a URL.
 * @param url URL to remove the first slash from.
 * @returns URL without the first slash.
 */
function removeFirstSlash(url?: string) {
  if (!url) return url
  return url?.replace(/^\//g, '')
}
