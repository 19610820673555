import React from 'react'
import TomoIcon from 'assets/imgs/market/tomo2x.png'
import classNames from 'classnames'

type Props = {
  emptyText?: React.ReactNode
  textClassName?: string
  containerClassName?: string
}
export function NoResult({
  emptyText,
  textClassName,
  containerClassName
}: Props) {
  return (
    <div
      className={classNames(
        containerClassName,
        'flex flex-col items-center justify-center gap-4 py-10'
      )}
    >
      <img src={TomoIcon} alt="empty icon" className="size-14" />
      <div className={classNames(textClassName, 'text-title/40')}>
        {emptyText || 'No search result'}
      </div>
    </div>
  )
}
