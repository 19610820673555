export const ACCESSKEY = import.meta.env.VITE_OKX_KEY
export const ACCESSPASSPHRASE = import.meta.env.VITE_OKX_PASSPHRASE
export const ACCESSPROJECT = import.meta.env.VITE_OKX_PROJECT
export const SECRETKEY = import.meta.env.VITE_OKX_SECRET
export const kApiBaseUrl = `${
  import.meta.env.VITE_AVPIM_API
}bot-server/okx/proxy`
export const kApiBaseUrlV2 = `${
  import.meta.env.VITE_TOMO_API
}socialLogin/projectWallet`
// export const kApiBaseUrl = 'https://www.okx.com'
export const kApiVersion = '/api/v5/dex'
