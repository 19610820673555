import axios from 'axios'
import { apiSign } from './sign'
import {
  BuyStatusRes,
  CryptoItem,
  CryptoReq,
  GetOrderStatusReq,
  IQuoteParams,
  OrderCreateReq,
  OrderCreateRes,
  OrderCreateResponse,
  QuoteRes,
  QuoteResponse,
  QuoteSide,
  RequiredFieldsReq,
  RequiredFieldsRes,
  SellStatusRes,
  SupportCryptoItem
} from './type'
import { CurrencyItem } from './type'
import { sentryInterceptors } from '@/utils/sentry/axiosInterceptors'

function getApi(dynamicHeaders: any) {
  // const token = localStorage.getItem('alchemy-accessToken')
  // if (token) {
  //   dynamicHeaders = { ...dynamicHeaders, Authorization: `Bearer ${token}` }
  // }
  const instance = axios.create({
    baseURL: `${import.meta.env.VITE_ALCHEMY_API}${
      import.meta.env.VITE_ALCHEMY_API_VERSION
    }`,
    headers: {
      appId: import.meta.env.VITE_ALCHEMY_APP_ID,
      ...dynamicHeaders
    }
  })
  sentryInterceptors(instance)
  return instance
}

export function getHeaderData(
  method: string,
  request_path: string,
  params?: any,
  token?: string
) {
  const timestamp = new Date().getTime().toString()
  const signature = apiSign(timestamp, method, request_path, params)
  if (token) {
    return {
      sign: signature,
      timestamp,
      'access-token': `${token}`
    }
  }
  return {
    sign: signature,
    timestamp
  }
}

export const getAccessToken = async (email: string) => {
  const headerData = getHeaderData(
    'POST',
    `${import.meta.env.VITE_ALCHEMY_API}${
      import.meta.env.VITE_ALCHEMY_API_VERSION
    }/merchant/getToken`,
    {
      email
    }
  )
  const api = getApi(headerData)
  const { data } = await api.post(`merchant/getToken`, { email })
  return data.data
}

export const getCurrencyList = async (
  type: string
): Promise<CurrencyItem[]> => {
  const headerData = getHeaderData(
    'GET',
    `${import.meta.env.VITE_ALCHEMY_API}${
      import.meta.env.VITE_ALCHEMY_API_VERSION
    }/merchant/fiat/list?type=${type}`
  )
  const api = getApi(headerData)
  const { data } = await api.get(`/merchant/fiat/list?type=${type}`)
  return data.data
}

export const getQuote = async (
  params: IQuoteParams
): Promise<QuoteResponse> => {
  const headerData = getHeaderData(
    'POST',
    `${import.meta.env.VITE_ALCHEMY_API}${
      import.meta.env.VITE_ALCHEMY_API_VERSION
    }/merchant/quotes`,
    params
  )
  const api = getApi(headerData)
  const { data } = await api.post(`merchant/quotes`, params)
  return data
}

export const getSupportedCryptoList = async (
  currency: string
): Promise<SupportCryptoItem[]> => {
  const headerData = getHeaderData(
    'GET',
    `${import.meta.env.VITE_ALCHEMY_API}${
      import.meta.env.VITE_ALCHEMY_API_VERSION
    }/merchant/crypto/list?fiat=${currency}`
  )
  const api = getApi(headerData)
  const { data } = await api.get(`/merchant/crypto/list?fiat=${currency}`)
  return data.data
}

export const getSupportedCryptosBuyToken = async (
  params: CryptoReq
): Promise<CryptoItem[]> => {
  const headerData = getHeaderData(
    'POST',
    `${import.meta.env.VITE_ALCHEMY_API}${
      import.meta.env.VITE_ALCHEMY_API_VERSION
    }/merchant/fiat/info`
  )
  const api = getApi(headerData)
  const { data } = await api.post(`/merchant/fiat/info`, params)
  return data.data
}

export const createCard = async (params: any) => {
  const token = localStorage.getItem('alchemy-accessToken')
  if (!token) return
  const headerData = getHeaderData(
    'POST',
    `${import.meta.env.VITE_ALCHEMY_API}${
      import.meta.env.VITE_ALCHEMY_API_VERSION
    }/merchant/payment/account/create`,
    params,
    token
  )
  const api = getApi(headerData)
  return await api.post(`/merchant/payment/account/create`, params)
  // return data.data
}

export const getRequiredFields = async (
  params: RequiredFieldsReq
): Promise<RequiredFieldsRes> => {
  const headerData = getHeaderData(
    'GET',
    `${import.meta.env.VITE_ALCHEMY_API}${
      import.meta.env.VITE_ALCHEMY_API_VERSION
    }/merchant/payment/requiredField?fiat=${params.fiat}&side=${
      params.side
    }&payWayCode=${params.payWayCode}`
  )
  const api = getApi(headerData)
  const { data } = await api.get(
    `/merchant/payment/requiredField?fiat=${params.fiat}&side=${params.side}&payWayCode=${params.payWayCode}`
  )
  return data.data
}

export const createOrder = async (
  params: OrderCreateReq
): Promise<OrderCreateResponse | undefined> => {
  const token = localStorage.getItem('alchemy-accessToken')
  if (!token) return
  const headerData = getHeaderData(
    'POST',
    `${import.meta.env.VITE_ALCHEMY_API}${
      import.meta.env.VITE_ALCHEMY_API_VERSION
    }/merchant/order/create`,
    params,
    token
  )
  const api = getApi(headerData)
  const { data } = await api.post(`/merchant/order/create`, params)
  return data
}

export const getOrderStatus = async (
  params: GetOrderStatusReq
): Promise<BuyStatusRes | SellStatusRes> => {
  const headerData = getHeaderData(
    'GET',
    `${import.meta.env.VITE_ALCHEMY_API}${
      import.meta.env.VITE_ALCHEMY_API_VERSION
    }/merchant/query/trade?merchantOrderNo=${params.merchantOrderNo}&side=${
      params.side
    }`
  )
  const api = getApi(headerData)
  const { data } = await api.get(
    `/merchant/query/trade?merchantOrderNo=${params.merchantOrderNo}&side=${params.side}`
  )
  return data.data
}
