import { TIcon } from '@/components/tmd'

interface IProps {
  content: string | JSX.Element
  title?: string | JSX.Element
  arrow?: boolean
  classNames?: string
}
const Tip = ({
  content,
  title = 'Attention',
  arrow = false,
  classNames = ''
}: IProps) => {
  return (
    <div
      className={
        'mb-4 flex  items-start gap-2 rounded-[8px] border border-l1 p-3 text-xs text-t3'
      }
    >
      <TIcon
        name="tg_wallet_present-facetiousness"
        className="text-orange"
        fontSize="20"
      />
      <div className="flex flex-1 items-center justify-between">
        <div className="flex flex-col">
          <span className="text-sm text-t1">{title}</span>
          {content ? <span>{content}</span> : <></>}
        </div>
        {arrow && (
          <TIcon
            name="tg_wallet_right-pointing-arrow"
            className="text-t1"
            fontSize="20"
          />
        )}
      </div>
    </div>
  )
}

export default Tip
