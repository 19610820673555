import React, { PropsWithChildren, useEffect } from 'react'
import Web3EVMProvider from './web3Provider'
import QueryClientProvider from './QueryClientProvider'
import useInitUser from '@/stores/userStore/hooks/useInitUser'

const Proviers = ({ children }: PropsWithChildren) => {
  useInitUser()
  return (
    <QueryClientProvider>
      <Web3EVMProvider>{children}</Web3EVMProvider>
    </QueryClientProvider>
  )
}

export default Proviers
