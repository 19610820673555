const LaunchHeadImg = () => (
  <div
    className={`launchHeadImg relative mb-[45px] mt-[22px] h-[379px] w-[380px] ${
      localStorage.theme ?? 'light'
    }`}
  >
    <div className="border-cy absolute size-full rounded-[380px]" />
    <div className="absolute left-[2px] top-[2px] h-[375px] w-[376px] rounded-[376px] bg-bg1" />

    <div className="border-in absolute left-[83px] top-[82px] h-[215px] w-[214px] rounded-[214px]" />
    <div className="absolute left-[85px] top-[84px] h-[211px] w-[210px]  rounded-[210px] bg-bg1" />

    <img
      className="absolute left-[146px] top-[150px]"
      width={82.85}
      height={78.804}
      src="/assets/imgs/tomo.svg"
    />
    <img
      className="absolute left-[46px] top-[35px]"
      src="/assets/imgs/chains/avax.svg"
      width={37}
      height={36}
    />
    <img
      className="absolute left-[278.5px] top-[34px]"
      src="/assets/imgs/chains/bnb.svg"
      width={47}
      height={47}
    />
    <img
      className="absolute left-[77px] top-[144px]"
      src="/assets/imgs/chains/eth.svg"
      width={31}
      height={31}
    />
    <img
      className="absolute left-[259px] top-[200px]"
      src="/assets/imgs/chains/btc.svg"
      width={61}
      height={61}
    />
    <img
      className="absolute left-[61px] top-[318px]"
      src="/assets/imgs/chains/ton.svg"
      width={65}
      height={65}
    />
    <img
      className="absolute left-[281px] top-[322px]"
      src="/assets/imgs/chains/sol.svg"
      width={44}
      height={44}
    />
  </div>
)

export default LaunchHeadImg
