import { LoadingIcon } from './Loading'

export const PageLoading = () => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: localStorage.theme === 'dark' ? '#12122a' : '#ffffff'
      }}
    >
      <LoadingIcon isLoading={true} size={80} textSize={20} marginTop={10} />
    </div>
  )
}
