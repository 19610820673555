import AdaptiveNumber, { NumberType } from '@/components/AdaptiveNumber'
import { TNumberInput } from '@/components/tmd'
import { ISwapTokenTypeV2 } from '@/pages/swap/hooks/useSwapTokensV2'
import SwapToken from '@/pages/swap/pageComponent/input/component/SwapToken'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import { MinStarAmount } from './StarSwapContent'

const StarSwapStarInput = ({
  token,
  disabled = false,
  value,
  onInput
}: {
  token: AssetsToken
  disabled?: boolean
  onInput: (v: string) => void
  value: string
}) => {
  const swapV2Token: ISwapTokenTypeV2 = {
    ...token,
    id: 0, // no use
    createdTime: '',
    displayName: '',
    imageUrl: token.image,
    isTomoji: false,
    priceChangeH24: 0,
    priceUsd: '',
    supportRango: false,
    updatedTime: '',
    volumeH24: '',
    chain: '',
    chainId: token.chainId.toString()
  }
  return (
    <div className="flex max-h-[53px] w-full max-w-full items-center justify-between gap-1 overflow-hidden py-[9.5px]">
      <SwapToken token={swapV2Token} chainId={''} disabled={true} />
      <div className="flex grow flex-col items-end overflow-hidden">
        <TNumberInput
          className="w-full text-right text-h3 font-bold outline-none placeholder:text-t4"
          min={250}
          disabled={disabled}
          value={value}
          allowDot={false}
          onChange={(value) => {
            onInput?.(value)
          }}
          placeholder={MinStarAmount}
        />
        {value !== '' && value !== '0' && (
          <span className="text-xs text-t3">
            <AdaptiveNumber
              type={NumberType.USD}
              value={Number(value) * (token?.price || 0)}
            ></AdaptiveNumber>
          </span>
        )}
      </div>
    </div>
  )
}

export default StarSwapStarInput
