import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import walletStore from '@/stores/walletStore'
import { useMemo } from 'react'

export default function useNativeToken(
  token: AssetsToken | { chainId: number } | undefined
) {
  const tokenList = walletStore.walletTokens
  const nativeToken = useMemo(() => {
    if (!token) return
    return tokenList?.find?.(
      (item) => item.chainId === token.chainId && item.isNative
    )
  }, [token, tokenList])
  return nativeToken
}
