import { useSetAtom } from 'jotai'
import React, { useEffect } from 'react'
import { pageSettingAtom } from 'state'

export type PageSetting = {
  navBar?: {
    back?: boolean
    title?: string | React.ReactNode
    show?: boolean
    onBack?: (() => void) | null
    right?: React.ReactNode
    sticky?: boolean
  }
  tabBar?: {
    show?: boolean
    active?: string
  }
}

export const DEFAULT_PAGE_SETTING = {
  navBar: {
    back: false,
    onBack: null,
    title: '',
    show: false,
    right: undefined,
    sticky: false
  },
  tabBar: {
    show: false,
    active: ''
  }
} as PageSetting

const usePageSetting = (props: PageSetting) => {
  const setPageSetting = useSetAtom(pageSettingAtom)
  const setPageNew = (props: PageSetting) => {
    setPageSetting((old) => {
      return {
        ...old,
        ...props
      }
    })
  }
  useEffect(() => {
    setPageNew(props)
    return () => {
      setPageSetting(DEFAULT_PAGE_SETTING)
    }
  }, [])

  return { setPageSetting: setPageNew }
}

export default usePageSetting
