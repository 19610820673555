import { AssetsToken } from '../tokenStore/type/AssetsToken'
import { onRampChain } from '@/proviers/web3Provider/chains'
import { SupportCryptoItem } from '@/api/ramp/type'
import { sortByPriceBalance, tokenMappings } from '../walletStore/utils'

const checkedFunc = (token: AssetsToken, list: AssetsToken[]) => {
  const map = tokenMappings(list)
  return !!map[token.id]
}

export const selectGasUSDTokensOnramp = (
  source: AssetsToken[],
  support: SupportCryptoItem[],
  walletTokens: AssetsToken[]
) => {
  const gasToken = source
    .filter((item) => item.isNative)
    .filter((item) => !!onRampChain[item.chainId])
    .filter((item) => {
      return support.find(
        (i) =>
          i.crypto.toLowerCase() === item.symbol.toLowerCase() &&
          i.network === onRampChain[item.chainId].chain
      )
    })

  const usdToken = source
    .filter(
      (item) =>
        item.symbol.toLowerCase() === 'usdt' ||
        item.symbol.toLowerCase() === 'usdc'
    )
    .filter((item) => onRampChain[item.chainId])
    .filter((item) => {
      return support.find(
        (i) =>
          i.crypto.toLowerCase() === item.symbol.toLowerCase() &&
          i.network === onRampChain[item.chainId].chain
      )
    })
  const sortprice: Array<AssetsToken> = sortByPriceBalance([
    ...gasToken,
    ...usdToken
  ])
  sortprice.sort((a, b) => {
    const m = checkedFunc(a, walletTokens) ? 1 : -1
    const n = checkedFunc(b, walletTokens) ? 1 : -1
    return n - m
  })
  return sortprice
}
