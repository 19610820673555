import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { storyTestnet } from '@wagmi/core/chains'

const icon = chainsSvgs.storyTestnetSvg
const networkType: IWeb3NetworkType = 'test'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...storyTestnet,
    rpcUrls: {
      default: { http: ['https://story-rpc01.originstake.com'] }
    }
  },
  id: storyTestnet.id,
  type: 'EVM',
  name: storyTestnet.name,
  icon: icon,
  networkType
}

export default chainInfo
