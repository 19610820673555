import SlideButton from 'react-slide-button'
import React, { useState } from 'react'
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app'
import { StatusButtonProps } from './PropsType'
import { mergeProps } from '../utils/get-default-props'
import { TIcon } from '../icon'
import classNames from 'classnames'
import LoadingSvg from '../svgs/loading.svg?react'
import { t } from 'i18next'

const defaultProps = {
  type: 'confirm',
  text: t('tg_wallet.continue'),
  disabled: false
}

export function StatusButton(p: StatusButtonProps) {
  const props = mergeProps(p, defaultProps)
  const { type, className, text, disabled, onConfirm, ...restProps } = props

  const [reset, setReset] = useState(0)
  const baseStyles = 'flex-none w-full flex items-center justify-center'
  const sizeStyles = 'h-13 w-full rounded-lg'
  const themeStyles = {
    'bg-green': type == 'success',
    'bg-red border border-l1': type == 'loading',
    'border-none bg-bg3': type == 'confirm'
  }
  const disabledStyles = 'bg-bg3 text-t4 text-base font-medium'

  const [handleImpact] = useHapticFeedback()

  if (type == 'success') {
    return (
      <div
        role="button"
        className={classNames(baseStyles, sizeStyles, themeStyles, className)}
      >
        <TIcon
          name="tg_wallet_finalize-facetiousness"
          fontSize="24"
          className="text-white"
        />
      </div>
    )
  }
  if (type == 'loading') {
    return (
      <div
        role="button"
        className={classNames(baseStyles, sizeStyles, themeStyles, className)}
      >
        <LoadingSvg className="size-6 text-white" />
      </div>
    )
  }

  if (disabled) {
    return (
      <div
        role="button"
        className={classNames(
          'cursor-not-allowed relative',
          baseStyles,
          sizeStyles,
          disabledStyles
        )}
      >
        <div
          className={`absolute left-0 top-0 flex size-13 items-center justify-center rounded-[8px] bg-t4`}
        >
          <TIcon name="tg_wallet_slider" fontSize="24" className="text-white" />
        </div>
        <div className={'flex items-center justify-center'}>{text}</div>
      </div>
    )
  }
  return (
    <SlideButton
      classList={classNames(
        'tmd-slide-btn',
        baseStyles,
        sizeStyles,
        themeStyles
      )}
      customCaretWidth={52}
      caretClassList={'rounded-lg !bg-red'}
      overlayClassList={`rounded-lg !bg-red !transition-none !min-w-[52px] max-w-full`}
      minSlideWidth={0.6}
      // delta={1}
      mainText={text}
      caret={
        <TIcon name="tg_wallet_slider" fontSize="24" className="text-white" />
      }
      onSlideDone={() => {
        try {
          if (navigator && 'vibrate' in navigator) {
            navigator.vibrate(50)
          } else {
            handleImpact('light')
          }
          onConfirm?.()
        } catch (error) {
          // console.error('Error:', error)
          setReset((prev) => prev + 1)
        }
      }}
      reset={reset}
    />
  )
}

/**
 * SuccessButton
 * @param params
 * @constructor
 */
export function SuccessButton(params: Omit<StatusButtonProps, 'type'>) {
  return <StatusButton type="success" {...params} />
}

/**
 * ConfirmButton
 * @param params
 * @constructor
 */
export function ConfirmButton(params: Omit<StatusButtonProps, 'type'>) {
  return <StatusButton type="confirm" {...params} />
}

/**
 * LoadingButton
 * @param params
 * @constructor
 */
export function LoadingButton(params: Omit<StatusButtonProps, 'type'>) {
  return <StatusButton type="loading" {...params} />
}
