import { useWebApp } from '@vkruglikov/react-telegram-web-app'

export default function useBiometricManager() {
  const WebApp = useWebApp()
  const biometryManager = WebApp?.BiometricManager

  const requestAccess = ({
    reason = 'We will use biometry to protect your account',
    callback
  }: {
    reason?: string
    callback: (success: boolean) => void
  }) => {
    if (!biometryManager?.isAccessGranted) {
      try {
        biometryManager.openSettings()
      } catch (err) {
        //
      }

      biometryManager?.requestAccess(
        {
          reason
        },
        (success: boolean) => {
          callback(success)
        }
      )
      return
    }
    callback(true)
  }

  const authenticate = ({
    reason = 'We need to authenticate you to continue',
    callback,
    failCallback
  }: {
    reason?: string
    callback: (success: boolean, token: string) => void
    failCallback?: (error: Error) => void
  }) => {
    try {
      biometryManager?.authenticate(
        {
          reason
        },
        callback
      )
      return
    } catch (error) {
      requestAccess({
        callback: (success) => {
          if (!success) {
            failCallback &&
              failCallback(new Error('You must allow biometry to continue'))
            return
          }

          biometryManager?.authenticate(
            {
              reason
            },
            callback
          )
        }
      })
    }
  }

  return {
    biometryManager,
    requestAccess,
    authenticate
  }
}
