import { roundCheckedWhiteSvg, spiner2Svg } from 'assets'
import classNames from 'classnames'

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  theme?: 'white' | 'ghost'
  shape?: 'round' | 'default'
  size?: 'default' | 'small' | 'large' | 'icon'
  block?: boolean
  status?: 'normal' | 'loading' | 'success'
  // | 'ghost-gray'
  // | 'ghost-black'
  // | 'ghost-primary'
}
export type ButtonStatusType = 'normal' | 'loading' | 'success'
export function Button(props: ButtonProps) {
  const {
    theme = 'primary',
    shape = 'default',
    size = 'default',
    disabled,
    block = false,
    status = 'normal',
    className,
    ...otherProps
  } = props
  return (
    <button
      disabled={disabled}
      {...otherProps}
      className={classNames(
        ' flex items-center justify-center font-medium disabled:bg-bg3 disabled:text-t4 text-base ',
        {
          ' cursor-not-allowed bg-gray-50 !text-title': disabled,
          ' w-full ': block,
          ' px-[16px] ': !block,
          ' w-[40px] h-[40px] rounded-[8px] !p-0 ': size === 'icon',
          ' bg-red text-[#FFFFFF] ': theme === 'primary',
          ' bg-[#F2F4F6] text-btn ': theme === 'white',
          ' bg-[#FFFFFF] border border-[#D1D1D8] border-solid ':
            theme === 'ghost',
          ' rounded-[8px] ': shape === 'default',
          ' rounded-full ': shape === 'round',
          '  h-[36px] text-[14px] ': size === 'default',
          '  h-[32px] text-[14px] ': size === 'small',
          '  h-[48px] text-[16px] ': size === 'large',
          ' !bg-[#17CF82]': status === 'success'

          // 'border border-white-300': theme === 'ghost-gray',
          // 'border border-black text-black': theme === 'ghost-black',
          // 'border border-primary text-primary': theme === 'ghost-primary'
        },
        className
      )}
    >
      {status === 'loading' && (
        <img src={spiner2Svg} className="size-9 animate-spin" />
      )}
      {status === 'success' && (
        <img src={roundCheckedWhiteSvg} className="size-9" />
      )}
      {status === 'normal' && otherProps.children}
    </button>
  )
}
