import { TToast } from '@/components/tmd'

export interface SendSuccessType {
  amount: string
  symbol: string
}

export const sendSuccessToast = ({ amount, symbol }: SendSuccessType) => {
  TToast.success(`${amount} ${symbol} has been sent`)
}

export const sendFaildToast = ({ amount, symbol }: SendSuccessType) => {
  TToast.error(
    `Sending ${amount} ${symbol} failed. Please check the network or increase the gas fee and try again`
  )
}
