import AdaptiveNumber, { NumberType } from '@/components/AdaptiveNumber'
import { ISwapTokenTypeV2 } from '@/pages/swap/hooks/useSwapTokensV2'
import SwapToken from '@/pages/swap/pageComponent/input/component/SwapToken'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import { effectiveBalance } from '@/utils/helper'

const StarSwapToInput = ({
  token,
  value,
  onClick
}: {
  token: AssetsToken
  value: string
  onClick: () => void
}) => {
  const swapV2Token: ISwapTokenTypeV2 = {
    ...token,
    id: 0, // no use
    createdTime: '',
    displayName: '',
    imageUrl: token.image,
    isTomoji: false,
    priceChangeH24: 0,
    priceUsd: '',
    supportRango: false,
    updatedTime: '',
    volumeH24: '',
    chain: '',
    chainId: token.chainId.toString()
  }
  return (
    <div className="flex max-h-[53px] w-full max-w-full items-center justify-between gap-1 overflow-hidden py-[9.5px]">
      <SwapToken
        token={swapV2Token}
        chainId={token.chainId}
        onClick={onClick}
      />
      <div className="flex grow flex-col items-end overflow-hidden">
        {/* <TNumberInput
          className="![&._adm-input-element]:placeholder:text-t4 w-full text-right text-h3 font-bold outline-none"
          min={250}
          value={value}
          style={{
            '--placeholder-color': 'var(--text-t1)'
          }}
          disabled={true}
          placeholder="0.00"
        /> */}
        {!value || value === '0' ? (
          <span className="w-full text-right text-h3 font-bold text-t4 outline-none">
            0.00
          </span>
        ) : (
          <span className="w-full text-right text-h3 font-bold text-t1 outline-none">
            {effectiveBalance(value)}
          </span>
        )}
        {value !== '' && value !== '0' && (
          <span className="text-xs text-t4">
            <AdaptiveNumber
              type={NumberType.USD}
              value={Number(value) * token.price}
            ></AdaptiveNumber>
          </span>
        )}
      </div>
    </div>
  )
}

export default StarSwapToInput
