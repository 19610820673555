import { configChainsInfoAtom } from '@/state'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { getConfigChainsAll, getConfigChainsSymbolAll } from 'api'
import { useAtom } from 'jotai'
import React from 'react'

const useChainConfig = () => {
  return useQuery({
    queryKey: ['getConfigChainsAll'],
    placeholderData: keepPreviousData,
    queryFn: getConfigChainsAll
  })
}

export const useChaiSymbolnConfig = () => {
  const [configChainsInfo, setConfigChainsInfo] = useAtom(configChainsInfoAtom)
  const query = useQuery({
    queryKey: ['getConfigChainsSymbolAll'],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const res = await getConfigChainsSymbolAll()
      if (res) setConfigChainsInfo(res)
      return res
    }
  })

  return {
    ...query,
    data: configChainsInfo
  }
}

export default useChainConfig
