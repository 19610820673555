export const strCompare = (str1: string, str2: string) => {
  return str1?.toLocaleLowerCase() === str2?.toLocaleLowerCase()
}

export const strLowerIncludes = (str1: string, str2: string) => {
  return str1.toLowerCase().includes(str2.toLowerCase())
}

type HexString = string
export const fromHex = (data: HexString) => {
  const str = Buffer.from(data, 'hex').toString()

  try {
    return JSON.parse(str)
  } catch (err) {
    return str
  }
}

export const toHex = (data: any) => {
  return Buffer.from(JSON.stringify(data)).toString('hex')
}
