import useUserStore from '@/stores/userStore/hooks/useUserStore'
import mixpanel from '@/utils/mixpanel'
import React from 'react'

const useMixpanel = () => {
  const { user } = useUserStore()
  const init = () => {
    try {
      mixpanel.identify(user.id)
      mixpanel.people.set({
        $name: user.nickname || user.username,
        $email: user.email
        // referer: ''
      })
    } catch (error) {
      console.warn('mixpanel', error)
    }
  }

  const trackBalance = ({
    fromAddress,
    symbol,
    amount,
    totalTokenUsd
  }: {
    fromAddress: string
    symbol: string
    amount: string
    totalTokenUsd: string
  }) => {
    try {
      init()
      mixpanel.track('token_balance', {
        wallet_address: fromAddress,
        token_symbol: symbol,
        amount: amount,
        usd: totalTokenUsd
      })
    } catch (error) {
      console.warn('mixpanel', error)
    }
  }

  const trackSwapFail = ({
    fromChain,
    toChain,
    fromTokenSymbol,
    toTokenSymbol,
    fromAmount,
    toAmount,
    totalTokenUsd,
    gasUsd,
    from_address,
    fail_reason,
    fail_code
  }: {
    fromChain: string
    toChain: string
    fromTokenSymbol: string
    toTokenSymbol: string
    fromAmount: string
    toAmount: string
    totalTokenUsd: string
    gasUsd: string
    from_address: string
    fail_reason: string
    fail_code: string
  }) => {
    try {
      init()
      mixpanel.track('swap_fail_reason', {
        chain: fromChain,
        token_symbol: fromTokenSymbol,
        to_chain: toChain,
        to_token_symbol: toTokenSymbol,
        from_amount: fromAmount,
        to_amount: toAmount,
        usd: totalTokenUsd,
        gas_usd: gasUsd,
        from_address: from_address,
        fail_reason: fail_reason,
        fail_code: fail_code
      })
    } catch (error) {
      console.warn('mixpanel', error)
    }
  }

  const trackSwap = ({
    fromChain,
    toChain,
    fromTokenSymbol,
    toTokenSymbol,
    fromAmount,
    toAmount,
    totalTokenUsd,
    providerType,
    status,
    gasUsd
  }: {
    fromChain: string
    toChain: string
    fromTokenSymbol: string
    toTokenSymbol: string
    fromAmount: string
    toAmount: string
    totalTokenUsd: string
    providerType: string
    status: boolean // true = success || false = failed
    gasUsd: string
  }) => {
    try {
      init()
      mixpanel.track('swap', {
        chain: fromChain,
        token_symbol: fromTokenSymbol,
        to_chain: toChain,
        to_token_symbol: toTokenSymbol,
        from_amount: fromAmount,
        to_amount: toAmount,
        usd: totalTokenUsd,
        provider_type: providerType,
        status: status,
        gas_usd: gasUsd
      })
    } catch (error) {
      console.warn('mixpanel', error)
    }
  }

  const trackSend = ({
    chain,
    tokenSymbol,
    amount,
    totalTokenUsd,
    status,
    gasUsd,
    fromAddress,
    toAddress
  }: {
    chain: string
    tokenSymbol: string
    amount: string
    totalTokenUsd: string
    fromAddress: string
    toAddress: string
    status: boolean // true = success || false = failed
    gasUsd: string
  }) => {
    try {
      init()
      mixpanel.track('token_transfer', {
        chain: chain,
        token_symbol: tokenSymbol,
        amount: amount,
        usd: totalTokenUsd,
        from_address: fromAddress,
        to_address: toAddress,
        gas_usd: gasUsd,
        status: status
      })
    } catch (error) {
      console.warn('mixpanel', error)
    }
  }

  return {
    init,
    trackBalance,
    trackSwap,
    trackSwapFail,
    trackSend
  }
}

export default useMixpanel
