import { useEffect, useState } from 'react'
import rampStore from '..'
import { reaction } from 'mobx'

const useRampStore = () => {
  const [historyList, setHistoryList] = useState(() => rampStore.historyList)
  useEffect(() => {
    const listReaction = reaction(
      () => rampStore.historyList,
      (v) => setHistoryList(v)
    )

    return () => {
      listReaction()
    }
  }, [])
  return {
    historyList
  }
}

export default useRampStore
