import classname from 'classnames'
const IntroduceCard = ({
  img,
  content,
  desc,
  title,
  classNames
}: {
  img: string
  content?: JSX.Element | string
  desc?: JSX.Element | string
  title?: JSX.Element | string
  classNames?: string
}) => {
  return (
    <div className={classname('flex flex-col items-center', classNames)}>
      <img className="introduce-img mb-[8px] size-[200px]" src={img} alt="" />
      <div>
        {title ? (
          <div className="introduce-title font-[bold] text-xl text-t1">
            {title}
          </div>
        ) : (
          <></>
        )}
        <div className="introduce-content mb-[18px] text-center text-xl font-semibold text-t1">
          {content}
        </div>
        {desc ? <div className="mb-[100px] h-[32px]">{desc}</div> : <></>}
      </div>
    </div>
  )
}

export default IntroduceCard
