import { Routers } from '@/router'
import { useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

const Union = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const merchantNo = queryParams.get('merchantNo') ?? ''
  console.log('merNo', merchantNo)

  useEffect(() => {
    // navigate(Routers.ramp.paymentStatus(merchantNo), { replace: true })
  }, [])

  const btnClick = () => {
    // navigate(Routers.ramp.paymentStatus(merchantNo), { replace: true })
    window.location.href =`https://tomotg-dev.tomo.inc/ramp/payment-status/merchantOrderNo=${merchantNo}`
      // import.meta.env.VITE_BOT_LINK + `?startapp=RAMP_${merchantNo}`
  }

  return (
    <div
      className="flex size-full flex-1 items-center justify-center"
      onClick={btnClick}
    >
      btn
    </div>
  )
}

export default Union
