import {
  alchemyPay,
  cryptoSentSvg,
  divide,
  inTransfer,
  confirmOrder,
  result,
  success,
  loading
} from '@/assets'
import Tip from '@/components/Tip'
import { Routers } from '@/router'
import { Button } from 'antd-mobile'
import classNames from 'classnames'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const OrderStatus: React.FC = () => {
  const navigate = useNavigate()
  const [status, setStatus] = useState<string>('pending')
  return (
    <div className="flex h-full flex-col justify-between pt-3">
      <div className="px-4">
        <div
          className={classNames(
            ' text-[20px] font-bold py-3',
            status === 'success' ? 'text-green' : 'text-primary'
          )}
        >
          {status === 'success'
            ? '🎉 Deposit successed'
            : ' 🚀 Crypto sending...'}
        </div>
        <div className="mb-3 flex items-start gap-2 rounded-xl bg-[#F9F9F9] px-2 py-3">
          <img className="size-[16px]" src={success} alt="" />
          <div className="flex flex-col text-lg font-[500] text-title">
            300 USDT has been sent to your wallet
            <span className="text-xs text-grey-18">
              {' '}
              Block confirmed (50/50)
            </span>
          </div>
        </div>
        <div className="mb-3 flex items-start gap-2 rounded-xl bg-[#F9F9F9] px-2 py-3">
          <img className="size-[16px]" src={success} alt="" />
          <div className="flex flex-col text-lg font-[500] text-title">
            Confirm Order
            <span className="text-xs text-grey-18">
              {' '}
              Your order has been confirmed
            </span>
          </div>
        </div>
        <div className="mb-3 flex items-start gap-2 rounded-xl bg-[#F9F9F9] px-2 py-3">
          <img
            className={classNames(
              'w-[16px] h-[16px]',
              status === 'pending' && 'animate-spin'
            )}
            src={status === 'success' ? success : loading}
            alt=""
          />
          <div className="flex flex-col text-lg font-[500] text-title">
            In transfer...
            <span className="text-xs text-grey-18">
              Your fiat is in transfer
            </span>
          </div>
        </div>
        <div className="mb-3 flex items-start gap-2 rounded-xl bg-[#F9F9F9] px-2 py-3">
          <img
            className={classNames(
              'w-[16px] h-[16px]',
              status === 'pending' && 'animate-spin'
            )}
            src={status === 'success' ? success : loading}
            alt=""
          />
          <div className="flex flex-col text-lg font-[500] text-title">
            In transfer...
            <span className="text-xs text-grey-18">
              Your fiat is in transfer
            </span>
          </div>
        </div>
        <Tip content="You may leave this page. Order updates will be sent to your email and can be checked in the order history."></Tip>
      </div>
      <div className="flex flex-col gap-4 px-4">
        <Button
          size="large"
          onClick={() => {
            navigate(Routers.ramp.history)
          }}
          color="primary"
          className="h-12 rounded-lg text-xl font-medium"
        >
          Order history →
        </Button>
        <div className="flex items-center justify-center gap-5">
          <span className="text-lg text-text3">Powered by</span>
          <img className="h-10" src={alchemyPay} alt="" />
        </div>
      </div>
    </div>
  )
}
