import { IOKXHistoryType } from '@/state'
import axios from 'axios'
import { sentryInterceptors } from '@/utils/sentry/axiosInterceptors'
import { mockTonOkxChainID } from 'config/ton'
import { HmacSHA256, enc } from 'crypto-js'
import { kApiBaseUrlV2 } from './okxApi/const'
const api = axios.create({
  baseURL: `${import.meta.env.VITE_AVPIM_API}bot-server/okx/proxy`
})
sentryInterceptors(api)
// const api = axios.create({ baseURL: 'https://www.okx.com' })
const OKX_MAIN_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
export const OKX_TON_MAIN_ADDRESS =
  'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c'
api.interceptors.request.use(
  (config) => {
    const date = new Date()
    // console.log(
    //   'request',
    //   import.meta.env,
    //   config,
    //   date.toISOString() +
    //     'GET' +
    //     config.url +
    //     (config.params
    //       ? '?' + new URLSearchParams(config.params).toString()
    //       : '')
    // )
    config.headers['OK-ACCESS-KEY'] = import.meta.env.VITE_OKX_KEY
    config.headers['OK-ACCESS-SIGN'] = enc.Base64.stringify(
      // The field order of headersParams should be consistent with the order of quoteParams.
      // example : quote  ==>   cryptoJS.HmacSHA256(timestamp + 'GET' + '/api/v5/dex/aggregator/quote?amount=1000000&chainId=1&toTokenAddress=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&fromTokenAddress=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', secretKey)

      HmacSHA256(
        date.toISOString() +
          config.method?.toUpperCase() +
          config.url +
          (config.params
            ? '?' + new URLSearchParams(config.params).toString()
            : '') +
          JSON.stringify(config.data),
        import.meta.env.VITE_OKX_SECRET
      )
    )
    config.headers['OK-ACCESS-TIMESTAMP'] = date.toISOString()
    config.headers['OK-ACCESS-PASSPHRASE'] = import.meta.env.VITE_OKX_PASSPHRASE
    config.headers['OK-ACCESS-PROJECT'] = import.meta.env.VITE_OKX_PROJECT

    /*
       else {
        // default token
        config.headers.Authorization = `Bearer ${IS_DEV ? devToken : prodToken}`
      }
      */

    return config
  },
  (error) => {
    if (error?.response?.status === 401) {
      return Promise.reject(error)
    }
  }
)

export const getAggregatorQuoteApi = async (params: {
  chainId: string
  amount: string
  fromTokenAddress: string
  toTokenAddress: string
  dexIds?: string
  priceImpactProtectionPercentage?: string
  feePercent?: string
}): Promise<any> => {
  params.fromTokenAddress = params.fromTokenAddress || OKX_MAIN_ADDRESS
  params.toTokenAddress = params.toTokenAddress || OKX_MAIN_ADDRESS
  const ret = await api.get(`/api/v5/dex/aggregator/quote`, {
    params
  })
  return ret.data
}
export const getAggregatorSwapApi = async (params: {
  chainId: string
  amount: string
  fromTokenAddress: string
  toTokenAddress: string
  slippage: string
  userWalletAddress: string
  refererAddress?: string
  swapReceiverAddress?: string
  feePercent?: string
  gaslimit?: string
  gasLevel?: string
  dexIds?: string
  solTokenAccountAddress?: string
  priceImpactProtectionPercentage?: string
  callDataMemo?: string
  toTokenReferrerAddress?: string
  computeUnitPrice?: string
  computeUnitLimit?: string
}): Promise<any> => {
  if (Number(params.chainId) !== mockTonOkxChainID) {
    params.fromTokenAddress = params.fromTokenAddress || OKX_MAIN_ADDRESS
    params.toTokenAddress = params.toTokenAddress || OKX_MAIN_ADDRESS
  } else {
    params.fromTokenAddress = params.fromTokenAddress || OKX_TON_MAIN_ADDRESS
    params.toTokenAddress = params.toTokenAddress || OKX_TON_MAIN_ADDRESS
  }
  const ret = await api.get(`/api/v5/dex/aggregator/swap`, {
    params
  })
  return ret.data
}

export const getApproveTransactionApi = async (params: {
  chainId: string
  tokenContractAddress: string
  approveAmount: string
}): Promise<any> => {
  params.tokenContractAddress = params.tokenContractAddress || OKX_MAIN_ADDRESS

  const ret = await api.get(`/api/v5/dex/aggregator/approve-transaction`, {
    params
  })
  return ret.data
}

export const getCrossChainQuoteApi = async (params: {
  fromChainId: number
  toChainId: number
  fromTokenAddress: string
  toTokenAddress: string
  amount: string
  sort?: number
  slippage: string
  feePercent?: string
  allowBridge?: number[]
  denyBridge?: number[]
  priceImpactProtectionPercentage?: string
}): Promise<any> => {
  params.fromTokenAddress = params.fromTokenAddress || OKX_MAIN_ADDRESS
  params.toTokenAddress = params.toTokenAddress || OKX_MAIN_ADDRESS
  const ret = await api.get(`/api/v5/dex/cross-chain/quote`, {
    params
  })
  return ret.data
}

export const getCrossChainBuildTxApi = async (params: {
  fromChainId: number
  toChainId: number
  fromTokenAddress: string
  toTokenAddress: string
  amount: string
  sort?: number
  slippage: string
  userWalletAddress: string
  allowBridge?: number[]
  denyBridge?: number[]
  receiveAddress?: string
  feePercent?: string
  referrerAddress?: string
  priceImpactProtectionPercentage?: string
  onlyBridge?: boolean
  memo?: string
}): Promise<any> => {
  params.fromTokenAddress = params.fromTokenAddress || OKX_MAIN_ADDRESS
  params.toTokenAddress = params.toTokenAddress || OKX_MAIN_ADDRESS
  const ret = await api.get(`/api/v5/dex/cross-chain/build-tx`, {
    params
  })
  return ret.data
}

export const getCrossChainStatusApi = async (params: {
  chainId?: number
  hash: string
}): Promise<any> => {
  const ret = await api.get(`/api/v5/dex/cross-chain/status`, {
    params
  })
  return ret.data
}

export const getSupportChainApi = async (): Promise<any> => {
  const ret = await api.get(`/api/v5/dex/aggregator/supported/chain`)
  return ret.data
}

export const getCrossChainSupportedChainApi = async (): Promise<any> => {
  const ret = await api.get(`/api/v5/dex/cross-chain/supported/chain`)
  return ret.data
}

export const getOKXTransactionStatus = async (params: {
  hash: string
  chainId?: string
}): Promise<any> => {
  const ret = await api.get(`/api/v5/dex/cross-chain/status`, { params })
  return ret.data
}

export const getOKXTransactionStatusV2 = async (params: {
  hash: string
  chainId?: string
}): Promise<any> => {
  try {
    const userState = window.localStorage.getItem('userState')
    const token = userState ? JSON.parse(userState).token : ''
    const ret = await axios.get(`${kApiBaseUrlV2}/okxDexCrossChainStatus`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params
    })
    return ret.data.result
  } catch (error) {
    return null
  }
}

interface OKXResponeType<T> {
  data: T
  msg: string
  code: string
}

export interface AllTokenBalancesByAddressRes {
  chainIndex: string
  tokenAddress: string
  symbol: string
  balance: string // unit ether
  tokenPrice: string
  tokenType: '1' | '2' // 1 token 2 ordinals
  isRiskToken: boolean
}

// const apiOKX = axios.create({
//   baseURL: `https://www.okx.com`
// })

// apiOKX.interceptors.request.use((config) => {
//   const date = new Date()
//   config.headers['OK-ACCESS-KEY'] = import.meta.env.VITE_OKX_KEY
//   config.headers['OK-ACCESS-SIGN'] = enc.Base64.stringify(
//     HmacSHA256(
//       date.toISOString() +
//         'GET' +
//         config.url +
//         (config.params
//           ? '?' + new URLSearchParams(config.params).toString()
//           : ''),
//       import.meta.env.VITE_OKX_SECRET
//     )
//   )
//   config.headers['OK-ACCESS-TIMESTAMP'] = date.toISOString()
//   config.headers['OK-ACCESS-PASSPHRASE'] = import.meta.env.VITE_OKX_PASSPHRASE
//   config.headers['OK-ACCESS-PROJECT'] = import.meta.env.VITE_OKX_PROJECT
//   return config
// })

export interface TokenPriceRes {
  chainIndex: string
  price: string
  time: string
  tokenAddress: string
}
export const getTokenCurrentPrice = async (
  chainId: string,
  tokenAddress = ''
): Promise<TokenPriceRes[] | null> => {
  try {
    const userState = window.localStorage.getItem('userState')
    const token = userState ? JSON.parse(userState).token : ''
    // '/api/v5/wallet/token/current-price',
    const ret = await axios.post(
      `${kApiBaseUrlV2}/okxWalletTokenCurrentPrice`,
      [
        {
          chainIndex: chainId,
          tokenAddress
        }
      ],
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return ret.data.result
  } catch (error) {
    return null
  }
}
