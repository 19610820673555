let timeout: NodeJS.Timeout

export const debounce = (
  func: (searchValue: any) => void,
  delay: number = 300
) => {
  return (searchValue: any) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func(searchValue), delay)
  }
}
