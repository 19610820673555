import { Button, Popup, PopupProps } from 'antd-mobile'
import React from 'react'
import backIcon from 'assets/imgs/back.svg'

export type DrawerProps = {
  title?: React.ReactNode
  children?: React.ReactNode
  showHead?: boolean
  popupProps?: PopupProps
}

export function Drawer({
  title,
  showHead = true,
  children,
  popupProps
}: DrawerProps) {
  const handleBack = () => {
    popupProps?.onClose?.()
  }
  return (
    <Popup
      destroyOnClose
      position="right"
      bodyClassName="h-screen w-screen"
      visible={popupProps?.visible}
    >
      {showHead && (
        <div className="flex items-center justify-between px-1">
          <Button
            onClick={handleBack}
            className="size-11 border-none active:opacity-60 active:before:hidden"
          >
            <img src={backIcon} alt="back" />
          </Button>
          <h5 className="text-xl font-semibold">{title}</h5>
          <div className="w-11" />
        </div>
      )}
      {children}
    </Popup>
  )
}
