import { useWebApp } from '@vkruglikov/react-telegram-web-app'
import { useEffect } from 'react'
import FullContainer from '@/components/FullContainer'
import { useNavigate } from 'react-router-dom'
import useUserStore from '@/stores/userStore/hooks/useUserStore'
import LaunchHeadImg from './components/LaunchHeadImg'
import LaunchControlBtn from './components/LaunchBtn'
import userStore from '@/stores/userStore'
import { useTranslation } from '@/components/tmd'
import './index.css'
import { TContainer } from '@/components/tmd'

const LaunchPage = () => {
  const webApp = useWebApp()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { pageRoute, userState } = useUserStore()

  useEffect(() => {
    if (!pageRoute) return
    navigate(pageRoute)
    userStore.updateRouteAction('')
  }, [navigate, pageRoute])

  useEffect(() => {
    webApp?.BackButton?.hide()
  }, [webApp])

  return (
    <TContainer className="overflow-y-auto pb-2">
      <div className="relative flex flex-none flex-col items-center bg-bg1">
        <LaunchHeadImg />
        <div className="flex flex-col items-center gap-[8px]">
          <h2 className="text-n3 font-bold text-t1">
            {t('tg_wallet_login.welcome')}
          </h2>
          <span className="w-full text-center text-sm text-t3">
            {t('tg_wallet_login.welcome_tip')}
          </span>
        </div>
        <LaunchControlBtn />
      </div>
    </TContainer>
  )
}

export default LaunchPage
