import { useEffect } from 'react'
import { useThemeParams } from '@vkruglikov/react-telegram-web-app'
import useTheme from './useTheme'

const useInitTheme = () => {
  const [tgTheme] = useThemeParams()
  const { setGivedTheme } = useTheme()

  useEffect(() => {
    if (!document) return
    if (!tgTheme) return
    if (!localStorage.theme) localStorage.theme = tgTheme
    console.log('useInitTheme1')
    const htmlElement = document.documentElement
    htmlElement.className = localStorage.theme

    const tgThemeChangeEvent = () => {
      if (localStorage.theme) return
      console.log('tgThemeChangeEvent')
      const themeVal = window.Telegram.WebApp.colorScheme as 'dark' | 'light'
      setGivedTheme(themeVal)
    }

    window.Telegram?.WebApp.onEvent('themeChanged', tgThemeChangeEvent)
    return () => {
      window.Telegram?.WebApp.offEvent('themeChanged', tgThemeChangeEvent)
    }
  }, [tgTheme])
}

export default useInitTheme
