import { useEffect, useState } from 'react'

const DefauthTheme = 'light'
export const staticTheme = localStorage.theme
const useTheme = () => {
  const [theme, setTheme] = useState(staticTheme || DefauthTheme)

  useEffect(() => {
    setTheme(localStorage.theme || DefauthTheme)
  }, [])

  const setGivedTheme = (the: string) => {
    const htmlElement = document.documentElement
    htmlElement.className = the
    localStorage.theme = the
    setTheme(the)
    const color = the === 'dark' ? '#12122a' : '#ffffff'
    window.Telegram.WebApp?.setHeaderColor(color)
    window.Telegram.WebApp?.setBackgroundColor(color)
    window.Telegram.WebApp?.setBottomBarColor(color)

    // console.log('setGivedTheme')
  }

  const changeTheme = () => {
    const htmlElement = document.documentElement
    const currentTheme = htmlElement.className
    if (currentTheme === 'light') {
      htmlElement.className = 'dark'
      localStorage.theme = 'dark'
      setTheme('dark')
      console.log('changeTheme1')
    } else {
      htmlElement.className = 'light'
      localStorage.theme = 'light'
      setTheme('light')
      console.log('changeTheme2')
    }
  }

  return {
    theme,
    setGivedTheme,
    changeTheme
  }
}

export default useTheme
