import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import TgStar from '@/assets/imgs/starForGas/tg-star.svg'
export const MockStarToken: AssetsToken = {
  symbol: 'STAR',
  isNative: false,
  address: '',
  image: TgStar,
  chainId: -1,
  price: 0.02, // 0.02$ per star
  decimals: 0
}
