import { getTokenCurrentPrice } from '@/api/okx'
import { mockTronOKXChainId } from '@/config/tron'
import chains from '@/proviers/web3Provider/chains'
import { useQuery } from '@tanstack/react-query'

const useTokenPrice = (
  chainId: string | undefined,
  tokenAddress = '',
  stopInterval?: boolean
) => {
  return useQuery({
    queryKey: ['tokenPrice', chainId],
    refetchInterval: () => (stopInterval ? false : 30000),
    queryFn: async () => {
      if (!chainId) {
        return null
      }
      let chainIndex = chainId
      if (Number(chainIndex) === chains.tron.id) {
        chainIndex = String(mockTronOKXChainId)
      }
      if (Number(chainId) === chains.ton.id) return undefined
      const data = await getTokenCurrentPrice(chainIndex, tokenAddress)
      return data?.[0] || null
    }
  })
}

export default useTokenPrice
