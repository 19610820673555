import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import SwapStarInput from './StarSwapStarInput'
import {
  Loading,
  TIcon,
  TIconButton,
  TStatusButton,
  TToast
} from '@/components/tmd'
import SwapInfo from './StarSwapInfo'
import { useEffect, useRef, useState } from 'react'
import SwapToInput from './StarSwapToInput'
import usePopup from '@/hooks/popup/usePopup'
import SelectToken from '@/pages/token/components/SelectToken'
import chains from '@/proviers/web3Provider/chains'
import { createStarInvoice } from '@/api'
import useLoginInfo from '@/hooks/useLoginInfo'
import { CreateStarInvoiceReqType } from '@/api/type'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { useWebApp } from '@vkruglikov/react-telegram-web-app'
import { StatusButtonType } from '@/components/tmd/status-button/PropsType'
import { InvoiceStatus } from '../utils/type'
import { parseUnits } from 'viem'
import { MockStarToken } from '../utils/const'
import walletStore from '@/stores/walletStore'
import useNativeToken from '@/pages/token/sendToken/hook/useNativeToken'
import useTokenPrice from '../hook/useTokenPrice'
import BigNumber from 'bignumber.js'
import { getChainByChainId } from '@/stores/walletStore/utils'
import allChain from '@/proviers/web3Provider/chains/wagmiConfig/allChain'
import Tip from '@/components/Tip'
import { errorContents } from '@/config/const'
import classNames from 'classnames'
import { mockTronOKXChainId } from '@/config/tron'
export const MinStarAmount = 100
const FeeRate = 0.5

export const StarSupportChains = [
  chains.ethereum.id,
  chains.bsc.id,
  chains.arbitrum.id,
  chains.base.id,
  chains.blast.id,
  chains.avalanche.id,
  chains.polygon.id,
  chains.scroll.id,
  chains.optimism.id,
  chains.linea.id,
  chains.solana.id,
  chains.tron.id, // tron is 19484
  chains.ton.id,
  chains.sui.id
]
const SwapContent = () => {
  const webApp = useWebApp()
  const navigate = useNavigate()
  const [search, setSearch] = useSearchParams()
  const chainId = Number(search.get('chainId') || chains.base.id)
  const amount = search.get('amount') || ''
  const to = search.get('to')
  const initGasToken = useNativeToken({ chainId })
  const [toToken, setToToken] = useState(initGasToken)
  const [inputAmount, setInputAmount] = useState('')
  const [outputAmount, setOutputAmount] = useState('')
  const [curChain, setCurChain] = useState(allChain)
  const [searchStr, setSearchStr] = useState('')
  const [buttonStatus, setButtonStatus] = useState<StatusButtonType>('confirm')
  const { user, getAddressByToken } = useLoginInfo()
  const stopInterval = ['loading', 'success'].includes(buttonStatus)
  const { data, isLoading } = useTokenPrice(
    toToken?.chainId?.toString(),
    '',
    stopInterval
  )

  const nativeTokenList = walletStore.walletTokens.filter(
    (token) => token.isNative && StarSupportChains.includes(token.chainId)
  )
  const hasCalcStar = useRef(false)
  // TODO price is 0 or null or undefined  = page loading

  const { setOpen, component } = usePopup({
    title: '',
    showCloseButton: false,
    content: (
      <SelectToken
        title="Select tokens"
        showSelectChain={true}
        curChain={curChain}
        onSelectChain={(chain) => setCurChain(chain)}
        classNames={classNames(
          '!h-[90vh]',
          '[&_.select-search]:mx-0 [&_.select-token-list]:mx-0 [&_.select-title]:ml-0'
        )}
        tokens={nativeTokenList.filter((token) => {
          if (curChain.id === allChain.id) {
            return token.symbol.toUpperCase().includes(searchStr.toUpperCase())
          } else {
            return (
              curChain.id === token.chainId &&
              token.symbol.toUpperCase().includes(searchStr.toUpperCase())
            )
          }
        })}
        isLoading={false}
        onSearch={(v: string) => {
          setSearchStr(v)
        }}
        onClick={(token) => {
          setToToken(token)
          setSearch({ chainId: token.chainId.toString() }, { replace: true })
          setOpen(false)
        }}
        emptyTips={
          StarSupportChains.includes(curChain.id)
            ? 'No search result'
            : 'This chain is not supported'
        }
      />
    )
  })

  const renderToToken: AssetsToken = {
    ...toToken!,
    price: Number(data?.price || toToken?.price || 0)
  }

  const unitRate = (() => {
    if (!renderToToken?.price) return BigNumber(0)
    return new BigNumber(MockStarToken.price * (1 - FeeRate)).div(
      new BigNumber(renderToToken.price)
    )
  })()

  const getStarAmount = () => {
    if (
      amount &&
      unitRate.gt(0) &&
      inputAmount === '' &&
      hasCalcStar.current === false
    ) {
      const starAmount = new BigNumber(amount)
        .times(1.2)
        .dividedToIntegerBy(unitRate)
      if (Number(starAmount) < MinStarAmount) {
        setInputAmount(String(MinStarAmount))
      } else {
        setInputAmount(String(starAmount))
      }
      hasCalcStar.current = true
    }
  }

  useEffect(() => {
    getStarAmount()
  }, [unitRate])

  useEffect(() => {
    if (!inputAmount || inputAmount === '0') {
      return setOutputAmount('0')
    }
    setOutputAmount(unitRate.times(inputAmount).toFixed())
  }, [unitRate, inputAmount])

  if (!toToken) {
    return (
      <Loading className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
    )
  }

  const curWalletAddress = getAddressByToken(renderToToken)

  const createInvoiceLink = async () => {
    const uuid = window.crypto.randomUUID() // only https
    setButtonStatus('loading')
    const reqData: CreateStarInvoiceReqType = {
      requestId: uuid.replaceAll('-', ''),
      telegramId: Number(user.tgId)!,
      chainId:
        renderToToken.chainId === chains.tron.id
          ? mockTronOKXChainId
          : renderToToken.chainId,
      fromAddress: '',
      toAddress: curWalletAddress,
      tokenContract: '',
      symbol: renderToToken.symbol,
      decimals: renderToToken.decimals,
      tokenAmount: parseUnits(outputAmount, renderToToken.decimals).toString(),
      tokenPrice: renderToToken.price,
      starAmount: Number(inputAmount),
      starUsdt: String(Number(inputAmount) * MockStarToken.price)
    }
    try {
      const { invoiceLink } = await createStarInvoice(reqData)
      webApp.openInvoice(invoiceLink, (status: InvoiceStatus) => {
        switch (status) {
          case InvoiceStatus.Failed:
            setButtonStatus('confirm')
            break
          case InvoiceStatus.Waiting:
            setButtonStatus('loading')
            break
          case InvoiceStatus.Paid:
            setButtonStatus('success')
            setTimeout(() => {
              navigate(
                `/star/result?chain_type=${getChainByChainId(chainId)?.type}${
                  to ? `&to=${to}` : ''
                }`
              )
            }, 3000)
            break
          case InvoiceStatus.Cancelled:
            setButtonStatus('confirm')
            break
        }
      })
    } catch (e) {
      setButtonStatus('confirm')
      if (typeof e?.response?.data?.code === 'number') {
        const code = e.response.data.code
        if (code === 2503) {
          TToast.error('Price change too much, please try again')
        } else {
          TToast.error(e.response.data.message)
        }
      } else {
        TToast.error(errorContents.serverError)
      }
    }
  }

  return (
    <>
      {component}
      <div className="mt-[32px]">
        <SwapStarInput
          token={MockStarToken}
          value={inputAmount}
          onInput={(v) => setInputAmount(v)}
        />
        <div className="my-[2px] flex items-center gap-[3px]">
          <div className="h-px grow bg-l1"></div>
          <TIconButton className="size-[36px]">
            <TIcon
              className="text-t1"
              fontSize="20"
              name="tg_wallet_reception"
            />
          </TIconButton>
          <div className="h-px grow bg-l1"></div>
        </div>
        <SwapToInput
          token={renderToToken}
          value={outputAmount}
          onClick={() => setOpen(true)}
        />
        {inputAmount !== '' && Number(inputAmount) < MinStarAmount ? (
          <div className="mt-[32px]">
            <Tip
              title={'Insufficient amount'}
              content={`The minimum input amount is ${MinStarAmount} STAR`}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="mt-[32px]">
          <TStatusButton
            text={'Continue'}
            type={buttonStatus}
            onConfirm={() => createInvoiceLink()}
            disabled={
              Number(inputAmount) < MinStarAmount || isLoading || unitRate.eq(0)
            }
          />
        </div>
        <SwapInfo
          rate={unitRate}
          loading={isLoading}
          wallet={curWalletAddress}
          token={renderToToken}
        />
      </div>
    </>
  )
}

export default SwapContent
