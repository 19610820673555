import { enc, HmacSHA256 } from 'crypto-js'

export function apiSign(
  timestamp: string,
  method: string,
  requestUrl: string,
  body: any
) {
  let content = timestamp + method.toUpperCase() + getPath(requestUrl)
  if (body) content = content + getJsonBody(body)
  const hmac = HmacSHA256(content, import.meta.env.VITE_ALCHEMY_SECRET_KEY)
  return hmac.toString(enc.Base64)
}

function getPath(requestUrl: string) {
  const uri = new URL(requestUrl)
  const path = uri.pathname
  const params = Array.from(uri.searchParams.entries())

  if (params.length === 0) {
    return path
  } else {
    const sortedParams = [...params].sort(([aKey], [bKey]) =>
      aKey.localeCompare(bKey)
    )
    const queryString = sortedParams
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    return `${path}?${queryString}`
  }
}

function getJsonBody(body: any) {
  let map = body
  if (Object.keys(map).length === 0) {
    return ''
  }

  map = removeEmptyKeys(map)
  map = sortObject(map)

  return JSON.stringify(map)
}

function removeEmptyKeys(map: any) {
  const retMap: any = {}

  for (const [key, value] of Object.entries(map)) {
    if (value !== null && value !== '') {
      retMap[key] = value
    }
  }

  return retMap
}

function sortObject(obj: any): any {
  if (typeof obj === 'object') {
    if (Array.isArray(obj)) {
      return sortList(obj)
    } else {
      return sortMap(obj)
    }
  }

  return obj
}

function sortMap(map: any) {
  const sortedMap = new Map(
    Object.entries(removeEmptyKeys(map)).sort(([aKey], [bKey]) =>
      aKey.localeCompare(bKey)
    )
  )

  for (const [key, value] of sortedMap.entries()) {
    if (typeof value === 'object') {
      sortedMap.set(key, sortObject(value))
    }
  }

  return Object.fromEntries(sortedMap.entries())
}

function sortList(list: any) {
  const objectList = []
  const intList = []
  const floatList = []
  const stringList = []
  const jsonArray = []

  for (const item of list) {
    if (typeof item === 'object') {
      jsonArray.push(item)
    } else if (Number.isInteger(item)) {
      intList.push(item)
    } else if (typeof item === 'number') {
      floatList.push(item)
    } else if (typeof item === 'string') {
      stringList.push(item)
    } else {
      intList.push(item)
    }
  }

  intList.sort((a, b) => a - b)
  floatList.sort((a, b) => a - b)
  stringList.sort()

  objectList.push(...intList, ...floatList, ...stringList, ...jsonArray)
  list.length = 0
  list.push(...objectList)

  const retList = []

  for (const item of list) {
    if (typeof item === 'object') {
      retList.push(sortObject(item))
    } else {
      retList.push(item)
    }
  }

  return retList
}
