import { coinWithBalance, Transaction } from '@mysten/sui/transactions'
import {
  GetTransactionBlockParams,
  SuiClient,
  SuiTransactionBlockResponse
} from '@mysten/sui/client'
import { formatUnits } from 'viem'
import { SUI_TYPE_ARG } from '@mysten/sui/utils'
import chains from '@/proviers/web3Provider/chains'
const suiEndPoint = 'https://fullnode.mainnet.sui.io:443'
export const SUI_TOKEN_ADDRESS = SUI_TYPE_ARG
let suiClient: SuiClient = new SuiClient({ url: suiEndPoint })
export const suiScanUrl = 'https://suiscan.xyz/mainnet/tx/'
export function getSuiClient(): SuiClient {
  if (suiClient) {
    return suiClient
  }
  const client = new SuiClient({ url: suiEndPoint })
  suiClient = client
  return suiClient
}

export const sendSuiTx = async ({
  bytes,
  signature
}: {
  bytes: Uint8Array
  signature: string
}): Promise<SuiTransactionBlockResponse> => {
  const res = await suiClient.executeTransactionBlock({
    transactionBlock: bytes,
    signature: signature
  })
  return res
}

export const getSendSuiCoinTx = async ({
  fromAddress,
  toAddress,
  amount,
  coinType
}: {
  fromAddress: string
  toAddress: string
  amount: string
  coinType?: string
}): Promise<{ transaction: string; bytes: Uint8Array } | null> => {
  const txb = new Transaction()
  txb.transferObjects(
    [coinWithBalance({ balance: BigInt(amount), type: coinType || undefined })],
    toAddress
  )
  try {
    txb.setSender(fromAddress)
    const transaction = (await txb.toJSON({ client: suiClient })).toString()
    const bytes = await txb.build({ client: suiClient })
    return { transaction, bytes }
  } catch (e) {
    console.warn(e)
    return null
  }
}

// only form send
export interface GetSuiSendGasType {
  fromAddress: string
  toAddress: string
  amount: string
  coinType?: string
}
export const getSuiSendGas = async ({
  fromAddress,
  toAddress,
  coinType
}: GetSuiSendGasType): Promise<string> => {
  const txb = new Transaction()

  txb.transferObjects(
    [coinWithBalance({ balance: BigInt('0'), type: coinType || undefined })],
    toAddress
  )
  txb.setSender(fromAddress)
  const bytes = await txb.build({ client: suiClient })
  try {
    const { effects } = await suiClient.dryRunTransactionBlock({
      transactionBlock: bytes
    })
    const totalGas =
      BigInt(effects.gasUsed.computationCost) +
      BigInt(effects.gasUsed.storageCost)
    return formatUnits(
      totalGas || 0n,
      chains.sui.chain?.nativeCurrency.decimals
    )
  } catch (e) {
    console.warn(e)
    return '0'
  }
}

export const querySuiTransaction = async ({
  digest,
  options
}: GetTransactionBlockParams) => {
  const result = await suiClient.getTransactionBlock({
    digest,
    options: { showEffects: true, ...options }
  })
  return result.effects?.status
}
