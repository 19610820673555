export const SENTRY_DSN =
  'https://f33f3842ca8b07500dc98ad8d51614d1@o4508120700289024.ingest.us.sentry.io/4508333782925312'

// Set `sampleRate` to 1.0 to capture 100%
// of events for error monitoring.
export const SENTRY_SAMPLE_RATE = 1.0

// Set `ignoreErrors` to ignore some errors.
export const SENTRY_IGNORE_ERRORS = ['Network Error']

// Set tracesSampleRate to 0.5 to capture 50%
// of transactions for performance monitoring.
export const SENTRY_TRACES_SAMPLE_RATE = 0.2

// Set denyUrls to ignore some urls.
export const SENTRY_DENY_URLS = ['localhost', '127.0.0.1']

/**
 * Severity levels.
 * this is a copy of the enum from @sentry/types
 */
export enum SeverityLevel {
  fatal = 'fatal',
  error = 'error',
  warning = 'warning',
  log = 'log',
  info = 'info',
  debug = 'debug'
}

/**
 * this is a list of success api business code
 */
export const SuccessHttpCodeList = [0, 10000, 20000, 8000]
