import useTheme from '@/stores/userStore/hooks/useTheme'
import IntroduceCard from './IntroduceCard'
import { starForGasSvg } from '@/assets'
const StarBuyCard = ({ classNames }: { classNames?: string }) => {
  const { theme } = useTheme()
  const lightTheme = theme === 'light'
  return (
    <>
      <IntroduceCard
        img={
          lightTheme
            ? starForGasSvg.WhatIsStarLight
            : starForGasSvg.WhatIsStarDark
        }
        content={'Use Telegram  star  to exchange crypto'}
        classNames={classNames}
      />
    </>
  )
}

export default StarBuyCard
