import { Image, IndexBar, TIcon } from '@/components/tmd'
import { IWeb3ChainType } from '@/proviers/web3Provider/type'
import { allChains } from '@/proviers/web3Provider/chains'
import './index.scss'
import classNames from 'classnames'
import { sortChainsForIndexBar } from '../../utils'
import { useMemo, useState } from 'react'
import walletStore from '@/stores/walletStore'
import AdaptiveNumber, { NumberType } from '@/components/AdaptiveNumber'
import { ChainsBalaceType } from '@/stores/walletStore/type'
import useTheme from '@/stores/userStore/hooks/useTheme'
import { onTelegramVibrate } from '@/utils'

const SelectChainItem = ({
  chain,
  current,
  onClick,
  className,
  balances
}: {
  chain: IWeb3ChainType
  current?: IWeb3ChainType
  onClick?: () => void
  className?: string
  balances: ChainsBalaceType
}) => {
  return (
    <div
      className={classNames(
        'tmd-index-bar-item flex cursor-pointer items-center space-x-3 px-5 py-1.5',
        className
      )}
      onClick={onClick}
    >
      {chain.id === -1 ? (
        <TIcon
          name="tg_wallet_primary-network"
          className="flex-none text-t1"
          fontSize="32"
        />
      ) : (
        <Image
          lazy
          className="size-8 flex-none rounded-full"
          src={chain.icon}
          alt="chain.name"
        />
      )}
      <div className="flex flex-1 flex-col overflow-hidden">
        <div className="truncate text-base font-medium text-t1">
          {chain.name}
        </div>
        <AdaptiveNumber
          value={Number(balances[chain.id] ?? 0) || 0}
          type={NumberType.USD}
          className="text-xs font-normal text-t3"
        />
      </div>

      {chain.id == current?.id ? (
        <TIcon
          className="flex-none text-t1"
          name="tg_wallet_finalize-facetiousness"
          fontSize="24"
        />
      ) : (
        <span className="w-6 flex-none"></span>
      )}
    </div>
  )
}

const SelectChainList = ({
  onConfirm,
  onClose,
  chains,
  current
}: {
  onConfirm: ((chain: IWeb3ChainType) => void) | undefined
  chains?: IWeb3ChainType[]
  current?: IWeb3ChainType
  onClose?: () => void
}) => {
  const chainsList = chains || allChains

  const defaultChain = current || chains[0]
  const groups = useMemo(() => {
    return sortChainsForIndexBar(chainsList)
  }, [chainsList])
  const chainsBalance = walletStore.getChainsTotalBalace.get()
  const [currentIndex, setCurrentIndex] = useState('')
  const { theme } = useTheme()

  return (
    <div className="no-scrollbar size-full text-xl">
      <IndexBar
        sticky={false}
        className={classNames('tmd-index-bar', {
          'tmd-index-bar-bubble-bg': theme == 'light',
          'tmd-index-bar-bubble-bg_dark': theme == 'dark'
        })}
        onIndexChange={(index: string) => {
          setCurrentIndex(index)
          onTelegramVibrate()
        }}
      >
        {groups.map((group) => {
          const { index, items } = group
          return (
            <IndexBar.Panel
              index={index}
              title={index == 'popular' ? 'popular networks' : index}
              key={index}
              brief={
                index == 'popular' ? (
                  <TIcon
                    name="tg_wallet_start_fill"
                    className={classNames('tmd-index-bar-bubble', {
                      'text-t3': currentIndex != index,
                      'text-bg1': currentIndex == index
                    })}
                    fontSize="10"
                  />
                ) : undefined
              }
              className={classNames('tmd-index-bar-panel', {
                'tmd-index-bar-anchor-hidden': index == 'all',
                'tmd-index-bar-anchor-space': !['all', 'popular'].includes(
                  index
                )
              })}
            >
              {items?.map((chain, itemIndex) => {
                return (
                  <SelectChainItem
                    key={chain.name}
                    chain={chain}
                    current={defaultChain}
                    onClick={() => {
                      onConfirm?.(chain)
                      onClose?.()
                    }}
                    className={classNames({
                      'mt-3': itemIndex > 0
                    })}
                    balances={chainsBalance}
                  />
                )
              })}
              {index == 'all' && (
                <div className="tmd-index-bar-hr" key={`${index}_hr`}></div>
              )}
            </IndexBar.Panel>
          )
        })}
      </IndexBar>
    </div>
  )
}

export default SelectChainList
