import React, { useCallback, useEffect, useState } from 'react'
import { Drawer, DrawerProps } from 'components/Drawer'
import { atom, useAtomValue, useSetAtom } from 'jotai'
import { useLocation, useParams } from 'react-router-dom'

// -----------------------------------------------------------------
export const SecondaryPageState = atom<DrawerProps>({
  title: null,
  children: null
})

// -----------------------------------------------------------------

export const useSecondaryPage = () => {
  const setPageState = useSetAtom(SecondaryPageState)

  const open = useCallback(
    (props: DrawerProps, url?: string) => {
      if (url) {
        history.pushState('', '', url)
      }

      document.documentElement.scrollTop = 0

      setPageState({
        ...props,
        popupProps: {
          ...(props.popupProps || {}),
          visible: true
        }
      })
    },
    [setPageState]
  )
  const prevUrl = location.href
  const close = useCallback(
    (needBack?: boolean) => {
      if (needBack) {
        history.pushState('', '', prevUrl)
      }

      setPageState({
        title: null,
        children: null,
        popupProps: {
          visible: false
        }
      })
    },
    [prevUrl, setPageState]
  )

  useEffect(() => {
    const pushState = history.pushState
    history.pushState = function (
      data: any,
      unused: string,
      url?: string | URL | null
    ) {
      if (data) {
        close()
      }
      return pushState.apply(history, [data, unused, url])
    }
  }, [])

  return {
    open,
    close
  }
}

// -----------------------------------------------------------------
export function SecondaryPage() {
  const pageState = useAtomValue(SecondaryPageState)
  return <Drawer {...pageState} />
}
